import React, { useEffect, useState } from 'react'
import Loading from '../Components/Loading'
import priceListService from '../Services/priceListService'
import moment from 'moment'

const PriceList = () => {

    const [loading, setLoading] = useState(false)
    const [PriceList, setPricelist] = useState([])

    useEffect(() => {
        const getPrices = async () => {
            try {
                setLoading(true)
                const response = await priceListService.fetchAll()
                setPricelist(response.priceList)
                setLoading(false)
                console.log(PriceList)
            }
            catch {

            }
        }
        getPrices()
    }, [])




    return (
        <div className="mx-16 my-4">
            <div className=" flex flex-col">
                {
                    loading ?
                        <div className='mt-56'>
                            <Loading />
                        </div>
                        :


                        <div className="mt-10">
                            {
                                PriceList.length > 0 ?
                                    <table className=" animate_animated animate_fadeIn">
                                        <thead className="bg-primary shadow-lg">
                                            <tr className="uppercase text-sm leading-normal w-full text-white">
                                                <th className=" rounded-tl-lg p-2  font-light text-center text-[13px] w-[1%]">
                                                    Id
                                                </th>
                                                <th className="  p-[2%] text-left text-[13px] w-[3%]">
                                                    Supplier Name
                                                </th>
                                                <th className="p-[1%] text-left text-[13px] w-[2%]">
                                                    Brand
                                                </th>
                                                <th className="p-[1%] text-left text-[13px] w-[2%]">
                                                    Vendor Code
                                                </th>
                                                <th className="p-[1%] text-left text-[13px] w-[3%]">
                                                    Product Name
                                                </th>
                                                <th className="p-[1%] text-left text-[13px] w-[3%]">
                                                    Date
                                                </th>
                                                <th className="p-[1%] text-center text-[13px] w-[2%]">
                                                    Order Price
                                                </th>
                                                <th className="p-[1%] text-center text-[13px] w-[2%]">
                                                    Retail Price
                                                </th>

                                                <th className=" rounded-tr-lg p-5 text-center text-[13px] w-[1%]">
                                                    Quantity
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {PriceList?.map((data, index) => (
                                                <tr className=' rounded-lg border-b border-gray-300 shadow-lg'>

                                                    <th className="p-[2%]  rounded-l-lg  font-light text-center text-[15px]  w-[1%] bg-gray-50">
                                                        {data.id}
                                                    </th>
                                                    <th className="p-[2%] text-left text-[15px] text-primary font-bold w-[3%] bg-gray-50">
                                                        {data.supplier_name}
                                                    </th>

                                                    <th className="p-1 text-left text-[11px] font-medium w-[2%] bg-gray-50">
                                                        {data.product_brand}
                                                    </th>
                                                    <th className="p-1 text-left text-[11px] font-medium w-[2%] bg-gray-50">
                                                        {data.vendor_code}
                                                    </th>
                                                    <th className="p-1 text-left text-[11px] font-medium w-[3%] bg-gray-50">
                                                        {data.product_title}
                                                    </th>
                                                    <th className="p-1 text-left text-[15px] font-light w-[3%] bg-gray-50">
                                                        {moment(data.date).format("LL")}
                                                    </th>
                                                    <th className="p-1 text-center font-semibold  text-[14px]  w-[2%] bg-gray-50">
                                                        {data.order_price}
                                                    </th>

                                                    <th className="p-1 text-center font-semibold text-[12px] w-[2%] bg-gray-50">
                                                        {data.price}
                                                    </th>
                                                    <th className="p-1 text-center font-light text-[12px] w-[1%] bg-gray-50 ">
                                                        {data.quantity}
                                                    </th>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> : "No Price List"
                            }
                        </div>
                }
            </div>
        </div >
    )
}

export default PriceList