import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import orderService from "../Services/orderService";
import Loading from "../Modals/Loading";
import OrderPayment from "../Modals/OrderPayment";
import * as xlsx from "xlsx";



const OrderView = () => {
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const getOrders = async () => {
            setLoading(true);
            try {
                const response = await orderService.fetchByInvoice(params.get("invoice"));
                setOrder(response.order);
            } catch (e) {
                console.error("Failed to fetch Orders", error);
                setError(true); // to handle errors
            }
            setLoading(false);
        };
        getOrders();
    }, []);


    const OpenModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    const handleStatus = () => {
        let status = ""
        if (order.status === "pending") {
            status = "confirmed"
        } else if (order.status === "confirmed") {
            status = "underprocess"
        } else if (order.status === "underprocess") {
            status = "readytodeliver"
        } else if (order.status === "readytodeliver") {
            status = "dispatched"
        } else if (order.status === "dispatched") {
            status = "deliveredorders"
        }
        orderService.updateStatus(order.id, { status }).then(response => {
            if (response.responseCode === 200) {
                navigate('/order')
            }
        })
    }

    const convertToCSV = (data) => {
        const headers = ['vendor_code', 'brand', 'quantity'];

        const rows = data.map(item => {
            return [item.vendor_code.toString(), item.brand, item.quantity].join(',');
        });

        return [headers.join(','), ...rows].join('\n');
    };

    const handleDownloadOrder = () => {
        // console.log(order)
        const groupedData = order.details.reduce((acc, item) => {
            if (!acc[item.supplier]) {
                acc[item.supplier] = [];
            }
            acc[item.supplier].push(item);
            return acc;
        }, {});
        console.log(groupedData)

        Object.keys(groupedData).forEach(brand => {
            const csvContent = convertToCSV(groupedData[brand]);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${brand}.csv`;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const downloadQuotation = () => {
        let suppliers = []
        for (const detail of order.details) {
            if (!suppliers.includes(parseInt(detail.supplier))) {
                suppliers.push(parseInt(detail.supplier))
            }
        }
        console.log(suppliers)

        for (const supplier of suppliers) {
            const transformedData = order.details.filter(v => parseInt(v.supplier) === parseInt(supplier)).map((item) => ({
                "Part Number": item.vendor_code,
                Brand: item.brand,
                Quantity: item.quantity,
                "Unit Price": item.price,
                "Total Price": item.quantity * item.price
            }));

            const fullData = [...transformedData];

            const worksheet = xlsx.utils.json_to_sheet(fullData);

            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, "Purchase_" + supplier);

            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `purchase_s_${supplier}_${moment().format()}.xlsx`);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
        }
    };


    return (
        <div className="my-10 mx-10 lg:mx-16">
            {loading ?
                <div className="mt-64">
                    <Loading />
                </div>
                :
                order ? (
                    <div>
                        <div className="flex flex-row justify-between border-b-2 pb-4 border-gray-300">
                            <div className="flex flex-row items-center">
                                <h1 className="font-bold text-2xl text-primary animate__animated animate__fadeInLeft">
                                    {order.invoice}
                                </h1>
                                <p className="ml-5">
                                    <p className="bg-red-600 px-4 py-[1px] rounded-md text-white text-[10px]">
                                        {order.status === "pending" ? "Pending" : order.status === "confirmed" ? "Confirmed" : order.status === "underprocess" ? "Under Process" : order.status === "readytodeliver" ? "Ready To Deliver" : order.status === "dispatched" ? "Dispatched" : "Delivered"}
                                    </p>
                                </p>
                                <p className="ml-5">
                                    <p className="bg-blue-600 px-4 py-[1px] rounded-md text-white text-[10px]">
                                        {order.order_type}
                                    </p>
                                </p>
                            </div>
                            <div className="flex flex-row justify-between gap-3 py-1">

                                <button className="bg-primary w-[180px] text-white py-1 px-2 rounded-md hover:shadow-md hover:shadow-gray-400 duration-300"
                                    onClick={() => OpenModal(order)}
                                > Add Payment </button>
                            </div>
                        </div>

                        <div className="grid grid-cols-5 w-[100%] mt-4">
                            <p className="flex flex-col font-semibold text-sm text-green-600">
                                Order Created At :{" "}
                                <span className="text-gray-500 font-medium">
                                    {moment(order?.created_at).format("LL")}
                                </span>
                            </p>
                            <p className="flex flex-col font-semibold text-sm text-green-600">
                                Order Date :{" "}
                                <span className="text-gray-500 font-medium">
                                    {moment(order?.order_date).format("LL")}
                                </span>
                            </p>
                            <p className="flex flex-col font-semibold text-sm text-green-600">
                                Order Delivery Date:{" "}
                                <span className="text-gray-500 font-medium">
                                    {moment(order?.delivery_date).format("LL")}
                                </span>
                            </p>
                            <p className="flex flex-col font-semibold text-sm text-blue-600">
                                Order Tracking Number :{" "}
                                <span className="text-gray-500 font-medium">
                                    {order.tracking_number}
                                </span>
                            </p>
                            <p className="flex flex-col font-semibold text-sm text-blue-600">
                                Payment Status :{" "}
                                <span className="text-gray-500 font-medium">
                                    {order.payment_status}
                                </span>
                            </p>
                        </div>

                        <h1 className="font-bold text-xl mt-8 text-primary"> Customer Details </h1>
                        <div className="flex justify-between">

                            <div className="flex flex-row gap-8 mt-2">
                                <div>
                                    <span className="block leading-none font-semibold">Customer Name</span>
                                    <span className="text-sm leading-none font-medium text-gray-500"> {order.recipent} </span>
                                </div>

                                <div>
                                    <span className="block leading-none font-semibold">Customer Email</span>
                                    <span className="text-sm leading-none font-medium text-gray-500 "> {order.email} </span>
                                </div>

                                <div>
                                    <span className="block leading-none font-semibold">Customer Phone</span>
                                    <span className="text-sm leading-none font-medium text-gray-500"> {order.phone} </span>
                                </div>
                            </div>
                            <div className="bg-primary w-[180px] text-white p-2 rounded-md cursor-pointer" onClick={() => downloadQuotation()}>Download Order Detail</div>
                        </div>


                        {order.receipt !== "0" && (
                            <div className="mt-4 text-red-500 text-lg hover:text-blue-500"> <a href={order.receipt} target="blank" rel='noopener noreferrer' > Click to View Receipt  </a> </div>

                        )}

                        <div className="mt-2">
                            <div className=' bg-white rounded-xl'>
                                <div className=' text-center pt-7 font-bold text-4xl text-primary'> Order Details</div>
                                <div className=' px- py-1 '>
                                    <table className='  border-separate border-spacing-y-2'>
                                        <thead>
                                            <tr className="uppercase w-full text-white ">
                                                <th className="py-[2%] border-b-[2px] rounded-l-xl text-left border-white bg-primary text-white font-bold text-[12px] pl-4  w-[3%]">
                                                    Id
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white  text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Vendor Code
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Brand
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Weight
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Price
                                                </th>
                                                <th className="py-[2%] border-b-[2px]  border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Quantity
                                                </th>
                                                <th className="py-[2%] border-b-[2px] rounded-r-xl border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Total Price
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {order.details && order.details.map((detail, index) => (
                                                <tr key={index} className='bg-gray-100 text-left text-sm'>
                                                    <td className='pl-4 w-[3%] py-2 rounded-l-xl font-bold text-blue-500'>{detail.id}.</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[11px] '>{detail.vendor_code}</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[12px] font-bold'>{detail.brand}</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[12px]'>{detail.weight}</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[12px]'>{detail.price} AED</td>
                                                    <td className='pl-4 w-[3%] py-2  text-[12px]'>{detail.quantity}</td>
                                                    <td className='pl-4 w-[3%] py-2 rounded-r-xl text-[12px]'>{parseFloat(detail.quantity * detail.price).toFixed(2)} AED</td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className="flex justify-between mt-8">
                            {
                                order.purchase == 0 &&
                                (<button className="bg-blue-600 text-white py-1 px-2 rounded-md hover:bg-blue-700"
                                    onClick={() => navigate('/purchase/add?invoice=' + order.invoice)}
                                > Create Purchase Order  </button>)
                            }
                            <div className={`flex flex-row  bg-gray-100 px-8 py-2 rounded-xl`}>
                                <div className={`flex flex-row`}>
                                    <p className={`text-black font-light`}>Order Amount:</p>
                                    <p className={`ml-3 text-primary font-bold`}>{order.amount}</p>
                                </div>
                                <div className={`w-[1px] h-full bg-black ml-5`} />
                                <div className={`flex flex-row ml-5`}>
                                    <p className={`text-black font-light`}>Remaining Amount:</p>
                                    <p className={`ml-3 text-primary font-bold`}>{parseFloat(order.amount - order.paid_amount).toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            {order.status === "deliveredorders" ?
                                <p className={`text-black px-5 py-2 font-bold uppercase`}>
                                    Delivered
                                </p>
                                :
                                <button onClick={() => handleStatus()} className={`bg-blue-600 rounded-md text-white px-5 py-2 font-bold uppercase`}>
                                    {order.status === "pending" ? "Confirm Order" : order.status === "confirmed" ? "Under Process" : order.status === "underprocess" ? "Ready To Deliver" : order.status === "readytodeliver" ? "Dispatch Order" : order.status === "dispatched" ? "Mark as Delivered" : "Delivered"}
                                </button>
                            }
                        </div>
                        <OrderPayment
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            order={selectedOrder}
                        />

                    </div>

                ) : (
                    <div className="text-center text-4xl text-black">The Order you are looking for is currently unavailable </div>
                )
            }
        </div>
    );
};

export default OrderView;
