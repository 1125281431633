import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Loading from '../Modals/Loading';
import { useNavigate } from 'react-router-dom';
import accountService from '../Services/accountService';

const Account = () => {

    const [trigger, setTrigger] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        const getAccounts = async () => {
            try {
                setLoading(true)
                const response = await accountService.fetchAllaccounts()
                setData(response.accounts)
                setLoading(false)
            }
            catch {

            }
        }
        getAccounts()
    }, [trigger])

    const handleStatusUpdate = async (id, value) => {
        await accountService.updateAccountStatus({ id: id, status: value === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE' }).then((res) => {
            setTrigger(!trigger)
        })

    }

    return (

        <div className="mx-16 my-4">
            {
                loading ?
                    <div className="">
                        <Loading />
                    </div>
                    :
                    <div>
                        <div className=" flex flex-col">
                            <div className="flex justify-end gap-4 my-2">
                                <button
                                    onClick={() => navigate('/accounts/add')}
                                    className="w-[180px] ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:bg-white hover:border-primary border-2 border-white hover:text-primary duration-300"
                                >
                                    Add Account
                                </button>
                            </div>
                            <div className="mt-2">
                                <table className=" animate__animated animate__fadeIn">
                                    <thead className="bg-primary">
                                        <tr className=" uppercase text-sm leading-normal w-full text-white">
                                            <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                                Account Name
                                            </th>
                                            <th className=" text-center pl-5 text-[13px] w-[3%] p-[2%] ">
                                                Account Title
                                            </th>
                                            <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                                Account Number
                                            </th>
                                            <th className="pl-5 text-center text-[13px] w-[4%] p-[2%]">
                                                Current Balance
                                            </th>
                                            <th className="rounded-tr-lg pl-5 text-center text-[13px] w-[4%] p-[2%]">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((value, index) => (
                                            <tr key={index} className="bg-gray-50">
                                                <td className="p-[2%] text-center text-[13px] w-[2%] border-b border-primary border-opacity-25">
                                                    {value.account_name}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-whtie  border-b border-primary border-opacity-25 ">
                                                    {value.account_title}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-whtie  border-b border-primary border-opacity-25 ">
                                                    {value.account_number}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-whtie  border-b border-primary border-opacity-25 ">
                                                    {value.current_balance}
                                                </td>
                                                <td className={`p-[2%] text-center text-[13px] w-[3%] text-white    border-b border-primary border-opacity-25`}>
                                                    <div className={`${value.status === 'ACTIVE' ? 'bg-green-500' : 'bg-red-500'} rounded-md w-[34%] text-center mx-auto cursor-pointer`} onClick={() => handleStatusUpdate(value.id, value.status)}>
                                                        {value.status}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Account
