import React, { useState, useEffect } from "react";
import orderService from '../Services/orderService';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Loading from "../Modals/Loading";


const Order = () => {
  const [order, setOrder] = useState()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading(true)
        const response = await orderService.fetchAll()
        setOrder(response.orders)
        setLoading(false)
      }
      catch {

      }
    }
    getOrders()
  }, [])

  return (

    <div className="mx-16 my-4">
      {
        loading ?
          <div className="">
            <Loading />
          </div>
          :
          <div>
            <div className=" flex flex-col">
              <div className="ml-auto">
                <button
                  onClick={() => navigate('/order/add')}
                  className="ml-3 py-2 px-4 w-[180px] bg-primary text-white rounded-lg hover:shadow-lg hover:shadow-gray-400 duration-300"
                >
                  Add Order
                </button>

              </div>
              <div className="mt-4">
                <table className=" animate__animated animate__fadeIn">
                  <thead className="bg-primary">
                    <tr className=" uppercase text-sm leading-normal w-full text-white">
                      <th className="rounded-tl-lg py-5 pl-5 text-left text-[13px] w-[2% p-[1%]]">
                        Invoice
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[2%] p-[2%]">
                        Amount
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[4%] p-[2%]">
                        Tracking No.
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[3%] p-[2%]">
                        Order Date
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[2%] p-[2%]">
                        Status
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[3%] p-[2%]">
                        Order Status
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[4%] p-[2%]">
                        View
                      </th>
                      <th className=" rounded-tr-lg pl-5 text-left text-[13px] w-[4%] p-[2%]">
                        Purchase Order
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.map((value, index) => (
                      <tr key={index} className="bg-gray-50">
                        {/*<td className="p-[2%] text-left text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">*/}
                        {/*  {data.id}*/}
                        {/*</td>*/}
                        <td className="p-[2%] text-left text-[13px] w-[2%]  border-b border-primary border-opacity-25 font-bold">
                          {value.invoice}
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                          {value.amount} AED
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[4%]  border-b border-primary border-opacity-25">
                          {value.tracking_number}
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[3%] bg-whtie border-b border-primary border-opacity-25">
                          {moment(value.order_date).format('ll')}
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                          <div className={`${value.payment_status === "paid" ? "bg-green-500" : value.payment_status === "notpaid" ? "bg-red-500" : "bg-yellow-600"} text-center rounded-md text-white uppercase`}>
                            {value.payment_status === "paid" ? "Paid" : value.payment_status === "notpaid" ? "Unpaid" : "Partial"}
                          </div>
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[3%]  border-b border-primary border-opacity-25">
                          {value.status === "pending" ? "Pending" : value.status === "confirmed" ? "Confirmed" : value.status === "underprocess" ? "Under Process" : value.status === "readytodeliver" ? "Ready To Deliver" : value.status === "dispatched" ? "Dispatched" : "Delivered"}
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[4%]  border-b border-primary border-opacity-25">
                          <button className="p-1 rounded-md text-primary"
                            onClick={() => navigate(`/order/view?invoice=${value.invoice}`)}
                          >View Order</button>
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[3%]  border-b border-primary border-opacity-25">
                          {/* <div> Created </div> */}
                          {value.purchase === 0 ? "No purchase " : "purchase  created"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default Order
