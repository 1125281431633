import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import { LoadingDots } from "../Components/LoadingDots";
import accountTransfersService from "../Services/accountsTransferService";
import monthlyVoucherService from "../Services/monthlyVoucher";
import { FaArrowLeft } from "react-icons/fa6";
import moment from "moment";

const AddMonthlyVoucher = () => {
    const navigate = useNavigate()
    const [responseError, setResponseError] = useState('')
    const [loading, setLoading] = useState(false)
    const [expenses, setExpenses] = useState([])
    const [totalExpense, setTotalExpense] = useState('')
    const [income, setIncome] = useState({})
    const [journalTotal, setJournalTotal] = useState('')
    const [hasDetails, setHasDetails] = useState(false)

    const [data, setData] = useState({
        start_date: "",
        end_date: ""
    });

    const [errors, setErrors] = useState({
        start_date: false,
        end_date: false
    })

    const [regex, setRegex] = useState({
        start_date: /.{1,50}$/,
        end_date: /.{1,50}$/
    })

    const handleChange = (v, n) => {
        if ((n === 'start_date' || n === 'end_date') && v > moment().format("YYYY-MM-DD")) {
            toast.error('Cannot select future date', { id: "1" });
            return;
        }
        setResponseError('')
        setData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const handleSubmit = () => {
        let hasError = false;
        if (data.start_date > data.end_date) {
            toast.error('Start date cannot be ahead of end date', { id: "1" });
            return;
        }
        Object.keys(data).map(v => {
            if (!regex[v]?.test(data[v])) {
                setErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })

        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else {
            setLoading(true)
            monthlyVoucherService.fetchMonthlyDetails(data).then(res => {
                setLoading(false)
                setExpenses(res.expensesTransactions)
                setTotalExpense(res.total_expense)
                setJournalTotal(res.journalTransaction.journal_voucher)
                setIncome(res.incomeTransactions)
                setHasDetails(true)
            })
        }
    }

    const createMonthlyVoucher = () => {
        let formData = { ...data, closing_income: income.total_income, closing_expense: totalExpense }
        setLoading(true)
        monthlyVoucherService.create(formData).then(res => {
            if (res.responseCode === 201) {
                toast.error(res.message, { id: "2" })
                setLoading(false)
                return;
            }
            toast.success('Created Monthly Voucher Successfully', { id: "2" })
            navigate('/monthly/voucher')
            setLoading(false)

        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="flex justify-center min-h-screen py-2">
            <div className="w-[100%] md:w-[79%] lg:w-[90%] flex justify-center my-24 rounded-3xl px-10 md:px-32">
                <div className=" w-full">
                    <div className="mb-6 mt-10">
                        <div className="my-1 cursor-pointer pt-10 pl-16" onClick={() => navigate('/monthly/voucher')}><FaArrowLeft className="w-10 h-6" /></div>
                        <h1 className="flex justify-center font-extrabold text-4xl text-primary">
                            Month Closing Voucher
                        </h1>
                    </div>
                    {
                        !hasDetails ?
                            <>
                                <div className="flex flex-col justify-center space-y-6 px-16 py-10">
                                    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                                        <div>
                                            <label
                                                className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                                Start Date
                                            </label>
                                            <input
                                                value={data.start_date}
                                                type="date"
                                                name="start_date"
                                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                                placeholder="Start Date"
                                                onKeyDown={handleKeyDown}
                                                className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['start_date'] ? "border-red-600" : "border-gray-300"}`}
                                            />
                                        </div>
                                        <div>
                                            <label
                                                className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                                End Date
                                            </label>
                                            <input
                                                value={data.end_date}
                                                type="date"
                                                name="end_date"
                                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                                placeholder="End Date"
                                                onKeyDown={handleKeyDown}
                                                className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['end_date'] ? "border-red-600" : "border-gray-300"}`}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="flex items-center justify-center flex-col">
                                    <button
                                        onClick={() => handleSubmit()}
                                        className={`flex justify-center self-center w-[30%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={loading}
                                    >
                                        <div className="mr-2 uppercase">
                                            Get Monthly Details
                                        </div>
                                        {loading ? <LoadingDots /> : ""}
                                    </button>
                                    <div className="mt-5"></div>
                                </div>
                            </>
                            : <div className="flex flex-col gap-6">
                                <div>
                                    <h1 className="text-2xl font-semibold">Monthly Voucher Sheet</h1>
                                    <table border="1" className="mx-auto" style={{ width: "60%", borderCollapse: "collapse" }}>
                                        <thead className="">
                                            <tr>
                                                <th>Title</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            <tr style={{ backgroundColor: "#58d67a", color: "white" }}>
                                                <td className="">Total Income</td>
                                                <td>{income?.total_income}</td>
                                            </tr>

                                            {expenses?.map((transaction, index) => (
                                                <tr key={index} style={{ backgroundColor: "#d13434", color: "white" }}>
                                                    <td>{transaction.expense_title || "N/A"}</td>
                                                    <td>{transaction.amount}</td>
                                                </tr>
                                            ))}

                                            <tr style={{ backgroundColor: "gray", color: "white", fontWeight: "bold" }}>
                                                <td>Total Expenses</td>
                                                <td>{totalExpense}</td>
                                            </tr>
                                            <tr style={{ backgroundColor: "#000000", color: "white", fontWeight: "bold" }}>
                                                <td>Net Total Profit/Expense</td>
                                                <td>{income.total_income - totalExpense}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="flex items-center justify-center flex-col my-4">
                                        <button
                                            onClick={() => createMonthlyVoucher()}
                                            className={`flex justify-center self-center w-[30%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            disabled={loading}
                                        >
                                            <div className="mr-2 uppercase">
                                                Create Voucher
                                            </div>
                                            {loading ? <LoadingDots /> : ""}
                                        </button>
                                        <div className="mt-5"></div>
                                    </div>
                                </div>
                            </div>

                    }
                </div>
            </div>
        </div >
    );
};

export default AddMonthlyVoucher;
