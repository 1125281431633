import React from 'react'

const OrderCustomers = () => {
  const breaks = [
    {
      name: "Break 1",
      duration: 15,
      code: "B1",
      break_type: "Short",
      sequence_no: 1,
      gap: "None",
    },
    {
      name: "Break 2",
      duration: 30,
      code: "B2",
      break_type: "Long",
      sequence_no: 2,
      gap: "15 mins",
    },
    {
      name: "Break 3",
      duration: 10,
      code: "B3",
      break_type: "Short",
      sequence_no: 3,
      gap: "5 mins",
    },
    {
      name: "Break 4",
      duration: 20,
      code: "B4",
      break_type: "Medium",
      sequence_no: 4,
      gap: "None",
    },
    {
      name: "Break 5",
      duration: 25,
      code: "B5",
      break_type: "Long",
      sequence_no: 5,
      gap: "10 mins",
    },
  ];
  return (
    <div className="mx-16 my-4">
      <div className=" flex flex-col">
        <div className="mt-10">
          <table className="shadow-md animate__animated animate__fadeIn">
            <thead className="bg-primary">
              <tr className=" uppercase text-sm leading-normal w-full text-white">
                <th className=" rounded-tl-lg p-[2%] text-left text-[13px] w-[4%]">
                  Name
                </th>
                <th className="p-[2%] text-left text-[13px] w-[4%]">
                  Duration
                </th>
                <th className="p-[2%] text-left text-[13px] w-[4%]">Code</th>
                <th className="p-[2%] text-left text-[13px] w-[2%]">
                  Break Type
                </th>
                <th className="p-[2%] text-left text-[13px] w-[5%]">
                  Sequence No.
                </th>
                <th className="p-[2%] text-left text-[13px] w-[3%]">Gap</th>
                <th className=" rounded-tr-lg p-[2%] text-left text-[13px] w-[2%]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {breaks.map((data, index) => (
                <tr key={index}>
                  <td className="p-[2%] text-left text-[13px] w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                    {data.name}
                  </td>
                  <td className="p-[2%] text-left text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                    {data.duration} mins
                  </td>
                  <td className="p-[2%] text-left text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                    {data.code}
                  </td>
                  <td className="p-[2%] text-left text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                    {data.break_type}
                  </td>
                  <td className="p-[2%] text-left text-[13px] w-[6%] bg-gray-50 border-b border-primary border-opacity-25">
                    {data.sequence_no}
                  </td>
                  <td className="p-[2%] text-left text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">
                    {data.gap}
                  </td>
                  <td className="p-[2%] text-left text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">
                    <div className="flex flex-row gap-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="blue"
                        class="size-5 hover:scale-110 duration-300"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="red"
                        class="size-5 hover:scale-110 duration-300"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default OrderCustomers