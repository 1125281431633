import axiosInstance from "./axiosInstance";

const monthlyVoucherService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/monthly/voucher/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchMonthlyDetails: async (payload) => {
        try {
            const response = await axiosInstance.post('/monthly/voucher/monthly/details', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/monthly/voucher/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default monthlyVoucherService