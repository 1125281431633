import React, { useEffect, useState } from "react";
import purchaseService from "../Services/purchaseService";
import { useLoaderData, useLocation } from "react-router-dom";
import moment from "moment";
import SupplierDebit from "../Modals/SupplierDebit";

const user = [
  {
    date: "20-Oct-2024",
    Reference: "Invoice No #1122",
    debit: "21,300",
    credit: "15,000",
    balance: "1,50,000",
  },
  {
    date: "18-Oct-2024",
    Reference: "Invoice No #2003",
    debit: "28,300",
    credit: "18,000",
    balance: "2,50,000",
  },
  {
    date: "10-Oct-2024",
    Reference: "Invoice No #1654",
    debit: "36,200",
    credit: "19,000",
    balance: "3,50,000",
  },
  {
    date: "26-Oct-2024",
    Reference: "Invoice No #5622",
    debit: "50,000",
    credit: "20,000",
    balance: "4,50,000",
  },
  {
    date: "17-Oct-2024",
    Reference: "Invoice No #8952",
    debit: "21,900",
    credit: "25,000",
    balance: "5,50,000",
  },
];

const SupplierLedger = () => {

  const [Ledger, setLedger] = useState([])
  const [supplier, setSupplier] = useState(null)
  const [Loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [start_date, setStartDate] = useState('')
  const [end_date, setEndDate] = useState('')

  const OpenModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const getCustomerLedger = async () => {
      try {
        setLoading(true);
        const response = await purchaseService.fetchSupplierLedger(params.get("id"));
        setSupplier(response.supplier);
        setLedger(response.ledger);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching customer ledger:", error);
        setLoading(false);
      }
    };
    getCustomerLedger();
  }, []);

  useEffect(() => {
    const getCustomerLedgerByDate = async () => {
      try {
        setLoading(true);
        const response = await purchaseService.fetchSupplierLedgerByDate(params.get("id"), { start_date, end_date });
        setSupplier(response.supplier);
        setLedger(response.ledger);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching customer ledger:", error);
        setLoading(false);
      }
    };
    getCustomerLedgerByDate();
  }, [start_date, end_date]);

  return (
    <div className="px-24 py-10">
      {supplier && (
        <div>
          <div className="bg-white shadow-lg p-8 mt-2 border-gray-300 border-2 rounded-3xl">
            <div className="flex flex-col">
              <div className="text-primary text-2xl font-bold">
                {supplier.name}
              </div>
              <div className="text-xl text-gray-500 mt-2">{supplier.company}</div>

              <div className="text-xl text-gray-500">{supplier.country} </div>

              <div className="text-xl text-gray-500">
                {" "}
                {supplier.address}
              </div>
            </div>
          </div>

          <div className="p-2 px-3 bg-green-500 ml-auto rounded-md w-32 text-white my-2 cursor-pointer"
            onClick={() => OpenModal()}
          >
            Add Payment
          </div>

          <div className="mt-10">
            <div className="flex flex-row gap-12 justify-end">
              <div className="flex gap-3 items-center">
                <label className="text-md text-gray-700 font-bold">
                  Start Date :{" "}
                </label>
                <input
                  type="date"
                  name="start_date"
                  onChange={(e) => { setStartDate(e.target.value) }}
                  className="border-b-2 border-gray-400 focus:outline-none focus:border-primary"
                />
              </div>
              <div className="flex gap-3 items-center">
                <label className="text-md text-gray-700 font-bold">
                  End Date :
                </label>
                <input
                  type="date"
                  name="end_date"
                  onChange={(e) => { setEndDate(e.target.value) }}
                  className="border-b-2 border-gray-400 focus:outline-none focus:border-primary"
                />
              </div>
            </div>
            <table className=" animate_animated animate_fadeIn border-separate border-spacing-y-4">
              <thead className="bg-primary shadow-lg border-separate-2 border-spacing-y-2">
                <tr className="uppercase text-sm leading-normal w-full text-white">
                  <th className="rounded-l-lg  p-[1.5%] text-left text-[13px] w-[2%]">
                    Date
                  </th>
                  <th className="  p-[1.5%] text-left text-[13px] w-[2%]">
                    Reference
                  </th>
                  <th className="  p-[1.5%] text-left text-[13px] w-[2%]">
                    Note
                  </th>
                  <th className="p-[1.5%] text-left text-[13px] w-[1%]">Credit</th>
                  <th className="p-[1.5%] text-left text-[13px] w-[1%]">Debit</th>
                  <th className="rounded-r-lg p-[1.5%] text-left text-[13px] w-[1%]">
                    Balance
                  </th>
                </tr>
              </thead>

              <tbody>
                {Ledger?.map((entry, index) => (
                  <tr key={index} className="rounded-lg border border-gray-400 shadow-sm shadow-gray-300">
                    <td className="py-[1%] px-[2%] text-left rounded-l-lg text-[15px] text-primary font-bold w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                      {entry.purchase_date ? moment(entry.purchase_date).format("ll") : moment(entry.date).format("ll")}
                    </td>
                    <td className="py-[1%] px-[2%] text-left text-[15px] text-gray-700 font-light w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                      {entry.type === "ORDER" ? `Order ${entry.id}` : `Payment for Order ${entry.purchase_id}`}
                    </td>
                    <td className="py-[1%] px-[2%] text-left text-[15px] text-gray-700 font-light w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                      {entry.note ? entry.note : "-"}
                    </td>
                    <td className="py-[1%] px-[2%] text-left text-[15px] font-medium w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                      {entry.type === "ORDER" ? entry.amount : "-"}
                    </td>
                    <td className="py-[1%] px-[2%] text-left text-[15px] font-medium w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                      {entry.type === "TRANSACTION" ? entry.amount : "-"}
                    </td>
                    <td className="py-[1%] px-[2%] rounded-r-lg text-left text-[15px] font-light w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                      {parseFloat(entry.balance).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-row items-center justify-end gap-4 mt-3">
            <div className="text-2xl font-semibold text-primary">
              {" "}
              End Balance :{" "}
            </div>
            <div className="text-lg font-normal text-gray-700">{parseFloat(Ledger[Ledger.length - 1]?.balance)?.toFixed(2)}</div>
          </div>
        </div>
      )}
      <SupplierDebit
        isOpen={isModalOpen}
        onClose={closeModal}
        supplier={params.get("id")}
      // trigger={trigger}
      // setTrigger={setTrigger}
      />
    </div>
  );
};

export default SupplierLedger;
