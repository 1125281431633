
import React, { useState } from 'react';
import SideBar from './Sidebar';

function ResponsiveSidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-0 mt-7 bg-white">
      <button onClick={toggleSidebar} className=" bg-blue-500 text-white rounded">
        {isOpen ? 'hidden' : 'Open'}
      </button>
      <SideBar isOpen={isOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
}

export default ResponsiveSidebar;