import React from "react";
import Topbar from "./Topbar";
import ResponsiveSidebar from "./ResponsiveSidebar";
import { useLocation } from "react-router-dom";

const Portal = ({ children }) => {

  const location = useLocation();
  return (
    <div>
    {location.pathname === '/login' ? (
                children
            ) :
                
     <div> <div className="fixed h-full lg:w-60  bg-gray-800">
        <ResponsiveSidebar />
      </div>

      {/* Main content */}
      <div className="overflow-y-auto flex-1 lg:ml-60 flex flex-col">
        <Topbar />
        <main className="flex-1">{children}</main>
      </div></div>
    }
    </div>
  );
};

export default Portal;
