import React from 'react';

const Loading = () => {
    return (
        <center>
            <div className="flex justify-center items-center mt-56">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
            </div>
        </center>
    );
};

export default Loading;