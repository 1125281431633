import axiosInstance from "./axiosInstance";

const purchaseService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/purchases/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    submitPurchase: async (payload) => {
        try {
            const response = await axiosInstance.post('/purchases/submitPurchase', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/purchases/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    single: async (id) => {
        try {
            const response = await axiosInstance.get(`/purchases/single/${id}`);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    makePayment: async (id, payload) => {
        try {
            const response = await axiosInstance.post('/purchases/makePayment/' + id, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchSupplierLedger: async (id) => {
        try {
            const response = await axiosInstance.get(`/purchases/supplierLedger/${id}`);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchSupplierLedgerByDate: async (id, payload) => {
        try {
            const response = await axiosInstance.post(`/purchases/supplierLedger/date/${id}`, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchAllSuppliersStats: async (id) => {
        try {
            const response = await axiosInstance.get(`/purchases/suppliers/stats/all`);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
}

export default purchaseService
