import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Loading from '../Modals/Loading';
import { useNavigate } from 'react-router-dom';
import quotationService from "../Services/quotationService";

const Quotation = () => {

  const [quotation, setQuotation] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading(true)
        const response = await quotationService.fetchAll()
        setQuotation(response.quotations)
        setLoading(false)
      }
      catch {

      }
    }
    getOrders()
  }, [])

  return (

    <div className="mx-16 my-4">
      {
        loading ?
          <div className="">
            <Loading />
          </div>
          :
          <div>
            <div className=" flex flex-col">
              <div className="ml-auto">
                <button
                  onClick={() => navigate('/quotation/add')}
                  className="w-[180px] ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:bg-white hover:border-primary border-2 border-white hover:text-primary duration-300"
                >
                  Add Quotation
                </button>
              </div>
              <div className="mt-4">
                <table className=" animate__animated animate__fadeIn">
                  <thead className="bg-primary">
                    <tr className=" uppercase text-sm leading-normal w-full text-white">
                      <th className=" text-left pl-5 text-[13px] w-[2%] p-[2%] ">
                        Quotation Date
                      </th>
                      <th className=" text-left pl-5 mr-6 text-[13px] w-[3%] p-[2%]">
                        Amount
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[2%] p-[2%]">
                        Order Status
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[2%] p-[2%]">
                        Status
                      </th>
                      <th className=" text-left pl-5 text-[13px] w-[3%] p-[2%]">
                        View
                      </th>
                      <th className=" rounded-tr-lg pl-5 text-left text-[13px] w-[3%] p-[2%]">
                        Purchase Order
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotation?.map((value, index) => (
                      <tr key={index} className="bg-gray-50">
                        <td className="p-[2%] text-left text-[13px] w-[2%] bg-whtie border-b border-primary border-opacity-25">
                          {moment(value.quotation_date).format('ll')}
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[3%]  border-b border-primary border-opacity-25">
                          {value.amount} AED
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                          <div className={`${value.payment_status === "paid" ? "bg-green-500" : value.payment_status === "notpaid" ? "bg-red-500" : "bg-yellow-600"} text-center w-fit px-2 py-1 rounded-md text-white uppercase`}>
                            {value.payment_status === "paid" ? "Paid" : value.payment_status === "notpaid" ? "Unpaid" : "Partial"}
                          </div>
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                          {value.status === "pending" ? "Pending" : value.status === "confirmed" ? "Confirmed" : value.status === "underprocess" ? "Under Process" : value.status === "readytodeliver" ? "Ready To Deliver" : value.status === "dispatched" ? "Dispatched" : "Delivered"}
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[3%]  border-b border-primary border-opacity-25">
                          <button className="p-1 rounded-md text-primary"
                            onClick={() => navigate(`/quotation/view?id=${value.id}`)}
                          >View Quotation</button>
                        </td>
                        <td className="p-[2%] text-left text-[13px] w-[3%]  border-b border-primary border-opacity-25">
                          {/* <div> Created </div> */}
                          {value.purchase === 0 ? "No Quotation " : "Quotation  created"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default Quotation
