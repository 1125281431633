// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Loading from "../Modals/Loading";
// import orderService from '../Services/orderService';
// import moment from 'moment';
// import newsService from "../Services/newsService";

// const News = () => {
//   const [news, setNews] = useState()
//   const [loading, setLoading] = useState(false)

//   const navigate = useNavigate()

//   useEffect(() => {
//     const getNews = async () => {
//       try {
//         setLoading(true)
//         const response = await newsService.fetchAllNews()
//         setNews(response.post)
//         setLoading(false)
//       }
//       catch {

//       }
//     }
//     getNews()
//   }, [])

//   const formatDate = (dateStr) => {
//     const date = new Date(dateStr);
//     return date.toLocaleDateString("en-CA"); // en-CA formats as YYYY-MM-DD
//   };

//   return (
//     <div className="mx-16 my-4">
//       <div className='flex justify-end mt-4'>
//                 <button
//                     onClick={() => { navigate('/news/add') }}
//                     className="ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:bg-gray-400"
//                 >
//                     Add News / Posts
//                 </button>

//             </div>
//       {loading ? (
//         <div>
//           <Loading />
//         </div>
//       ) : (
//         <div>
//           <div className="flex flex-col">
//             <div className="mt-10">
//               <table className="shadow-md animate__animated animate__fadeIn">
//                 <thead className="bg-primary">
//                   <tr className="uppercase text-sm leading-normal w-full text-white">
//                     <th className="rounded-tl-lg p-[2%] text-left text-[13px] w-[4%]">
//                       Title
//                     </th>
//                     <th className="p-[2%] text-left text-[13px] w-[4%]">
//                       Description
//                     </th>
//                     <th className="p-[2%] text-left text-[13px] w-[1%]">
//                       Date
//                     </th>
//                     <th className="p-[2%] text-left text-[13px] w-[1%]">
//                       Action
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {news?.map((value, index) => (
//                     <tr key={index}>
//                       <td className="p-[2%] text-left text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25 font-bold">
//                         {value.title}
//                       </td>
//                       <td className="p-[2%] text-left text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
//                         {value.description}
//                       </td>
//                       <td className="p-[2%] text-left text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
//                         {formatDate(value.date)}
//                       </td>
//                       <td className="p-[2%] text-left text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
//                           <button className="p-1 rounded-md text-primary"
//                             // onClick={hello}
//                           >Delete</button>
//                         </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default News;


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Modals/Loading";
import newsService from "../Services/newsService";
import moment from 'moment';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getNews = async () => {
      try {
        setLoading(true);
        const response = await newsService.fetchAllNews();
        setNews(response.post); // Assuming your response data has 'post'
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Error fetching news');
      }
    };
    getNews();
  }, []);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-CA"); // en-CA formats as YYYY-MM-DD
  };

  // const handleDelete = async (id) => {
  //   if (window.confirm("Are you sure you want to delete this news item?")) {
  //     try {
  //       await newsService.delete(id);
  //       toast.success('News deleted successfully');
  //       // Update the state to remove the deleted post
  //       setNews((prevNews) => prevNews.filter(item => item.id !== id));
  //     } catch (error) {
  //       toast.error('Error deleting news');
  //     }
  //   }
  // };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this news item? This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1EA56C',
      cancelButtonColor: '#3C3C3C',
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await newsService.delete(id);
          toast.success('News deleted successfully');
          // Update the state to remove the deleted post
          setNews((prevNews) => prevNews.filter(item => item.id !== id));
        } catch (error) {
          toast.error('Error deleting news');
          Swal.fire(
            'Error!',
            'There was a problem deleting the news item.',
            'error'
          );
        }
      }
    });
  };

  return (
    <div className="mx-16 my-4">
      <div className='flex justify-end mt-4'>
        <button
          onClick={() => { navigate('/news/add') }}
          className="ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:bg-white border-2 border-white duration-300 hover:border-primary hover:text-pretty hover:text-primary"
        >
          Add News / Posts
        </button>
      </div>

      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <div className="flex flex-col">
            <div className="mt-10">
              <table className="shadow-md animate__animated animate__fadeIn">
                <thead className="bg-primary">
                  <tr className="uppercase text-sm leading-normal w-full text-white">
                    <th className="rounded-tl-lg p-[2%] text-left text-[13px] w-[1%]">
                      Title
                    </th>
                    <th className="p-[2%] text-center text-[13px] w-[4%]">
                      Description
                    </th>
                    <th className="p-[2%] text-left text-[13px] w-[1%]">
                      Date
                    </th>
                    <th className="rounded-tr-lg p-[2%] text-left text-[13px] w-[1%]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {news?.map((value, index) => (
                    <tr key={index}>
                      <td className="p-[2%] text-left text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25 font-bold">
                        {value.title}
                      </td>
                      <td className="p-[2%] text-center text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                        {value.description}
                      </td>
                      <td className="p-[2%] text-left text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                        {formatDate(value.date)}
                      </td>
                      <td className="p-[2%] text-left text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                        <button 
                          className="p-1 rounded-md text-primary hover:text-red-600"
                          onClick={() => handleDelete(value.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default News;

