import React, { useEffect, useRef, useState } from 'react'
import productService from "../Services/productService";
import Pagination from "../Components/Pagination";
import Loading from '../Modals/Loading';

const Products = () => {
    const [siblingCount, setSiblingCount] = useState(0);
    const [boundaryCount, setBoundaryCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [products, setProducts] = useState([])
    const [trigger, setTrigger] = useState(false)
    const [loadAllData, setLoadAllData] = useState(false)

    const isInitialRender = useRef(true);
    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        console.log(data)
        setProducts(data.slice(indexOfFirstItem, indexOfLastItem));
    }, [data, trigger])

    useEffect(() => {
        const getProducts = async () => {
            try {
                if (data.length >= currentPage * 10) {
                    const indexOfLastItem = currentPage * itemsPerPage;
                    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
                    setProducts(data.slice(indexOfFirstItem, indexOfLastItem));
                    return;
                }
                setLoading(true)
                const response = await productService.fetchAllPaginated({ page: currentPage, pageSize: itemsPerPage })
                setData((prevData) => [...prevData, ...response.products]);

                setTotalItems(response.total);
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        getProducts()
    }, [currentPage])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleLoadAllData = async () => {
        setLoading(true)
        try {
            const response = await productService.fetchAll()
            setData(response.products);
            setTotalItems(response.products.length);
            setLoadAllData(true)
            setCurrentPage(1)
            setSiblingCount(1)
            setBoundaryCount(1)
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="mx-16 my-4">
            {
                !loadAllData ?
                    <div className="flex justify-end mt-4">
                        <button
                            onClick={handleLoadAllData}
                            className="ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:shadow-lg duration-300 hover:shadow-gray-400"
                            disabled={loading}
                        >
                            Load All Data
                        </button>
                    </div> : ""
            }
            <div className=" flex flex-col">
                <div className="mt-10">
                    {
                        loading ? <Loading /> :
                            <table className="shadow-md animate__animated animate__fadeIn">
                                <thead className="bg-primary">
                                    <tr className="uppercase text-sm leading-normal w-full text-white">
                                        <th className="rounded-tl-lg p-[2%] text-left text-[13px] w-[1%]">Id</th>
                                        <th className="p-[2%] text-left text-[13px] w-[2%]">
                                            Brand
                                        </th>
                                        <th className="p-[2%] text-left  text-[13px] w-[2%]">
                                            Name
                                        </th>
                                        <th className="p-[2%]  text-left  text-[13px] w-[2%]">
                                            Part No.
                                        </th>
                                        <th className="p-[2%]  text-left text-[13px] w-[2%]">
                                            Quantity
                                        </th>
                                        <th className="p-[2%] text-left  text-[13px] w-[2%]">
                                            Weight
                                        </th>
                                        <th className="p-[2%] text-left   text-[13px] w-[2%]">

                                            Delivery
                                        </th>
                                        <th className="p-[2%] text-left text-[13px] w-[2%]">
                                            Supplier
                                        </th>
                                        <th className="rounded-tr-lg p-[2%]  text-left text-[13px] w-[2%]">
                                            Price
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {products?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="p-[2%] text-left text-[13px] font-bold text-blue-600 w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.id}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] font-bold w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.vendor_code}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.title}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.brand}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.quantity}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.weight}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.delivery}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">
                                                {item.supplier_code}
                                            </td>
                                            <td className="p-[2%] text-left text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">{item.price}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                    }
                    {!loading &&
                        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={handlePageChange}
                            siblingCount={siblingCount}
                            boundaryCount={boundaryCount}
                        />
                    }

                </div>
            </div>
        </div>
    )
}

export default Products
