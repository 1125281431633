import React, { useState } from "react";
import toast from "react-hot-toast";
import { LoadingDots } from "../Components/LoadingDots";
import { useNavigate } from "react-router-dom";
import newsService from "../Services/newsService";

const AddNews = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    title: "",
    description: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    date: false,
  });

  const [regex, setRegex] = useState({
    title: /.{3,50}$/,
    description: /.{3,50}$/,
    date: /^\d{4}-\d{2}-\d{2}$/,
  });

  const handleChange = (v, n) => {
    setData((d) => ({ ...d, [n]: v }));
    if (v.length > 0) {
      setErrors((prevState) => ({ ...prevState, [n]: false }));
    }
  };

  const handleSubmit = () => {
    let hasError = false;
    Object.keys(data).map((v) => {
      if (!regex[v]?.test(data[v])) {
        setErrors((prevState) => ({ ...prevState, [v]: true }));
        hasError = true;
      }
    });
    console.log(hasError);
    if (hasError) {
      toast.error("Please fill the form correctly", { id: "1" });
    } else {
      setLoading(true);
      newsService.create(data).then((res) => {
        setLoading(false);
        toast.success("News created successfully", { id: "2" });
        navigate("/news");
      });
    }
  };

  return (
    <div className="mx-16 my-4">
      <div className="flex justify-center">
        <h1 className="text-2xl font-bold text-center mt-5 uppercase text-primary">
          Add Posts
        </h1>
      </div>

      <div className="flex flex-col justify-center space-y-6 px-36 py-10 bg-gray-100 rounded-3xl mt-6">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-16">
          <div>
            <label
              className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}
            >
              Title
            </label>
            <input
              value={data.title}
              name="title"
              onChange={(e) => handleChange(e.target.value, e.target.name)}
              placeholder="News Title"
              className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary 0 border-gray-200 focus:border-primary  w-full ${errors["name"] ? "border-red-600" : "border-gray-300"
                }`}
            />
          </div>
        </div>
        <div>
          <label
            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}
          >
            Description
          </label>
          <textarea
            value={data.description}
            name="description"
            onChange={(e) => handleChange(e.target.value, e.target.name)}
            placeholder="News Detail"
            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors["description"] ? "border-red-600" : "border-gray-300"
              }`}
            rows={5} // You can adjust the rows attribute to control the initial height of the textarea
          />
        </div>


        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-16">
          <div>
            <label
              className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}
            >
              Date
            </label>
            <input
              type="date"
              value={data.date}
              name="date"
              onChange={(e) => handleChange(e.target.value, e.target.name)}
              placeholder="Select Date"
              className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors["date"] ? "border-red-600" : "border-gray-300"
                }`}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center">
        <button
          onClick={() => handleSubmit()}
          className={`flex justify-center self-center mt-6 w-[25%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          disabled={loading}
        >
          <div className="mr-2">ADD</div>
          {loading ? <LoadingDots /> : ""}
        </button>
      </div>
    </div>
  );
};

export default AddNews;
