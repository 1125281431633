import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoMdClose } from "react-icons/io";
import orderService from "../Services/orderService";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const OrderPayment = ({ isOpen, onClose, order }) => {

    const [errors, setErrors] = useState({});
    const [amount, setAmount] = useState(0)
    const [method, setMethod] = useState(0)
    const [date, setDate] = useState(0)
    const [note, setNote] = useState(order?.reference)

    useEffect(() => {
        console.log(method)
    }, [method])
    const navigate = useNavigate()

    const handleSubmit = () => {
        if (amount > 0) {
            orderService.makePayment(order.id, { paid_amount: amount, method: method, date: moment(date["$d"]).format("YYYY-MM-DD") }).then(response => {
                orderService.makePayment(order.id, { paid_amount: amount, method: method, date: date, note: note }).then(response => {

                    if (response.responseCode === 200) {
                        onClose()
                        navigate('/order', { replace: true })
                    }
                    else {
                        // toast.success("chal nikal bhai ")
                    }
                })

            })
        }
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-md shadow-lg w-[550px] h-[700px]">
                <div className='flex justify-end hover:cursor-pointer ' onClick={onClose}> <IoMdClose size={25} className='hover:text-red-700' /></div>
                <div className='bg-slate-200 py-10 mt-10 w-[400px] mx-auto shadow-lg'>

                    <div className="text-xl font-semibold  text-center  ">Enter Amount to Order</div>

                    <div className=' flex flex-col items-center justify-center mx-14 mt-8 '>
                        <label className='text-left '> Select a Date </label>
                        <input
                            type='date'
                            name='date'
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder='Enter Amount '
                            className='w-full py-2 bg-white rounded-md focus:outline-none px-2 text-black'
                        />
                    </div>

                    <div className=' flex flex-col items-center justify-center mx-14 mt-4 '>
                        <label className='text-left '> Enter an Amount </label>
                        <input
                            type='number'
                            name='amount'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder='Enter Amount '
                            className='w-full py-2 bg-white rounded-md focus:outline-none px-2 text-black'
                        />
                    </div>
                    <div className=' flex flex-col items-center justify-center mx-14 mt-4 '>
                        <label className='text-left '> Select a payment method </label>

                        <select
                            name='amount'
                            value={method}
                            onChange={(e) => setMethod(e.target.value)}
                            placeholder='Enter Amount '
                            className='w-full py-2 bg-white rounded-md focus:outline-none px-2 '
                        >
                            <option value="" selected>  Select a payment Method </option>
                            <option value="CASH"> Cash </option>
                            <option value="BANK"> Bank </option>
                            <option value="CHEQUE"> Cheque </option>
                            <option value="CARD"> Card </option>


                        </select>


                    </div>
                    <div className=' flex flex-col items-center justify-center mx-14 mt-4 '>
                        <label className='text-left '> Enter a Specific Note</label>
                        <textarea
                            type
                            name='note'
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            placeholder='Enter a Note '
                            className='w-full py-2 bg-white rounded-md focus:outline-none px-2 text-black'
                        />
                    </div>



                    <div className="flex justify-center space-x-3 mt-5">

                        <button
                            className="px-3 py-1 bg-blue-600 text-white rounded-md"
                            onClick={() => handleSubmit()}
                        >
                            Confirm
                        </button>
                    </div>

                </div>
            </div>
        </div >



    );
};

export default OrderPayment;
