import axiosInstance from "./axiosInstance";

const productService = {
    fetchAll: async (payload) => {
        try {
            const response = await axiosInstance.get('/products/all', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchAllPaginated: async (payload) => {
        try {
            const response = await axiosInstance.post('/products/paginated/all', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    searchByVendor: async (vendor) => {
        try {
            const response = await axiosInstance.get('/products/search/' + vendor);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    searchByPart: async (vendor) => {
        try {
            const response = await axiosInstance.post('/products/searchByVendor', { vendor: vendor });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    searchByVendorAndCustomer: async (vendor, customer) => {
        try {
            const response = await axiosInstance.post('/products/searchByVendorAndCustomer', { vendor: vendor, customer: customer });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchTotal: async () => {
        try {
            const response = await axiosInstance.get('/products/total');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    import: async (payload) => {
        try {
            const response = await axiosInstance.post('/products/importProducts', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    deleteByDate: async (payload) => {
        try {
            const response = await axiosInstance.delete('/products/deleteByDate', { data: payload });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    }
}

export default productService;
