import axiosInstance from "./axiosInstance";

const priceListService = {

    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/pricelist/all');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    importProducts: async (payload) => {
        try {
            const response = await axiosInstance.post('/priceList/import/products', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    single: async (payload) => {
        try {
            const response = await axiosInstance.post('/priceList/single', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    all: async () => {
        try {
            const response = await axiosInstance.get('/priceList/all');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    priceListByCustomer: async (customer) => {
        try {
            const response = await axiosInstance.get(`/priceList/customer/special/${customer}`);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    }
}

export default priceListService;
