import React, { useState, useEffect } from 'react'
import Loading from '../Modals/Loading'
import purchaseService from '../Services/purchaseService'
import { useNavigate } from 'react-router-dom'
import moment from "moment";

const PurchaseOrder = () => {

    const [loading, setLoading] = useState(false)
    const [purchases, setPurchases] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        const getPurchase = async () => {
            try {
                // setLoading(true)
                const response = await purchaseService.fetchAll()
                setPurchases(response.purchases)
                // setLoading(false)
            }
            catch {

            }
        }
        getPurchase()
    }, [])

    return (
        <div className="mx-16 my-4">
            {
                loading ?
                    <div className="">
                        <Loading />
                    </div>
                    :
                    <div>
                        <div className=" flex flex-col">
                            <div className="ml-auto">
                                <button
                                    onClick={() => navigate('/purchase/add')}
                                    className="w-[180px] ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:bg-gray-400"
                                >
                                    Add Order
                                </button>

                            </div>
                            <div className="mt-4">
                                <table className="shadow-md animate__animated animate__fadeIn">
                                    <thead className="bg-primary">
                                        <tr className=" uppercase text-sm leading-normal w-full text-white">
                                            <th className="rounded-tl-lg p-[2%] text-center text-[13px] w-[2%]">
                                                ID
                                            </th>
                                            <th className="p-[2%] text-center text-[13px] w-[2%]">
                                                Amount
                                            </th>
                                            <th className="p-[2%] text-center text-[13px] w-[3%]">
                                                Supplier
                                            </th>
                                            <th className="p-[2%] text-center text-[13px] w-[3%]">
                                                Purchase Date
                                            </th>
                                            <th className="rounded-tr-lg p-[2%] text-center text-[13px] w-[4%]">
                                                View
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchases?.map((value, index) => (
                                            <tr
                                                key={index}
                                            >
                                                <td className="p-[2%] text-center text-[13px] w-[2%] bg-gray-50 border-b border-primary border-opacity-25 text-blue-500 font-bold">
                                                    {value.id}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                                                    {parseFloat(value.amount).toFixed(2)} AED
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">
                                                    {value.company}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-gray-50 border-b border-primary border-opacity-25">
                                                    {moment(value.purchase_date).format('ll')}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[4%] bg-gray-50 border-b border-primary border-opacity-25">
                                                    <button className="p-1 rounded-md text-primary"
                                                        onClick={() => navigate(`/purchase/view?id=${value.id}`)}
                                                    >View Order</button>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default PurchaseOrder
