import axiosInstance from "./axiosInstance";

const supplierService = {
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/suppliers/all');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/suppliers/create', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    update: async (id, payload) => {
        try {
            const response = await axiosInstance.post(`/suppliers/update/${id}`, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete(`/suppliers/delete/${id}`);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },

}

export default supplierService;
