import React, { useState } from "react";
import { FaBookJournalWhills, FaXmark } from "react-icons/fa6";
import { SiExpensify } from "react-icons/si";
import { FcDocument, FcWorkflow, FcStatistics, FcProcess, FcComboChart, FcNews, FcBullish, FcBusiness, FcBearish, FcCurrencyExchange, FcCalculator } from "react-icons/fc";
import { BiPurchaseTag, BiPurchaseTagAlt } from "react-icons/bi";
import { GiPriceTag, GiBanknote } from "react-icons/gi";
import { MdPayments, MdCalendarMonth, MdOutlineAccountBalance, MdPrecisionManufacturing } from "react-icons/md";
import { AiOutlinePayCircle } from "react-icons/ai";
import { GrTransaction } from "react-icons/gr";
import { IoIosJournal } from "react-icons/io";
import { PiRobotThin } from "react-icons/pi";
import { TiNews } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";

const panel = [
  { name: "Dashboard", path: "/dashboard", icon: <FcStatistics />, sub: false },
  { name: "Import List", path: "/importproducts", icon: <FcProcess />, sub: false },
  { name: "Price List ", path: "/pricelist", icon: <GiPriceTag />, sub: false },
  { name: "Quotation", path: "/quotation", icon: <BiPurchaseTagAlt />, sub: false },
  { name: "Products", path: "/products", icon: <FcWorkflow />, sub: false },
  { name: "Customers", path: "/users", icon: <PiRobotThin />, sub: false },
  { name: "Orders", path: "/order", icon: <FcComboChart />, sub: false },
  { name: "Suppliers", path: "/suppliers", icon: <MdPrecisionManufacturing />, sub: false },
  { name: "Purchase Order", path: "/purchase", icon: <FcCurrencyExchange />, sub: false },
  {
    name: "Accounts",
    path: "/accounts",
    icon: <MdOutlineAccountBalance />,
    sub: true,
    subMenu: [
      { name: "Accounts", path: "/accounts", icon: <FcBusiness /> },
      { name: "Expenses", path: "/expenses", icon: <FcBearish /> },
      { name: "Expenses Head", path: "/expenses/head", icon: <MdPayments /> },
      { name: "Incomes", path: "/incomes", icon: <FcBullish /> },
      { name: "Transactions", path: "/transactions", icon: <GrTransaction /> },
      { name: "Journal", path: "/journals", icon: <FaBookJournalWhills /> },
      { name: "Monthly Voucher", path: "/monthly/voucher", icon: <FcCalculator /> }
    ]
  },
  { name: "Car Makers", path: "/carmakers", icon: <FcDocument />, sub: false },
  { name: "News", path: "/news", icon: <FcNews />, sub: false },
];

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  return (
      <div>
        <div
            className={`fixed inset-y-0 z-50 left-0 bg-gray-50 text-white w-64 transform lg:translate-x-0 ${isOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-300 overflow-scroll`}
        >
          <div className="flex justify-center items-center mt-3 text-white h-16 w-full">
            <div className="text-center text-[28px] text-primary font-extrabold">
              DBS Spare Parts
            </div>
            <button
                onClick={toggleSidebar}
                className="absolute right-4 text-gray-300 lg:hidden"
            >
              <FaXmark size={20} />
            </button>
          </div>
          <div>
            {panel.map((item, index) => (
                <div
                    key={index}
                    className="mt-2 text-black ml-5 w-[85%]"
                    onClick={() => {
                      if (!item.sub) {
                        if (location.pathname !== item.path) {
                          navigate(item.path);
                        }
                      } else {
                        setExpandedIndex(expandedIndex === index ? null : index);
                      }
                    }}
                >
                  <div
                      className={`py-[8px] font-semibold cursor-pointer flex items-center pl-2 ${location.pathname === item.path
                          ? "bg-primary bg-opacity-10 shadow-md text-primary rounded-xl font-semibold"
                          : "text-black hover:bg-white hover:text-black hover:rounded-xl duration-500 hover:cursor-pointer border-l-4 border-r-4 border-transparent"
                      }`}
                  >
                    <div
                        className={`mr-2 ${location.pathname === item.path
                            ? "bg-white rounded-full p-1"
                            : "p-1"
                        }`}
                    >
                      {React.cloneElement(item.icon, { className: "h-4 w-4" })}
                    </div>
                    <div className="text-1xl">{item.name}</div>
                  </div>

                  {item.sub && expandedIndex === index && (
                      <div className="ml-8 mt-2">
                        {item.subMenu.map((subItem, subIndex) => (
                            <div
                                key={subIndex}
                                className={`py-[6px] font-semibold cursor-pointer flex items-center pl-2 ${location.pathname === subItem.path
                                    ? "text-primary"
                                    : "text-black hover:text-primary"
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (location.pathname !== subItem.path) {
                                    navigate(subItem.path);
                                  }
                                }}
                            >
                              <div className="mr-2">
                                {React.cloneElement(subItem.icon, { className: "h-4 w-4" })}
                              </div>
                              <div className="text-sm">{subItem.name}</div>
                            </div>
                        ))}
                      </div>
                  )}
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default Sidebar;
