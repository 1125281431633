import axiosInstance from "./axiosInstance";

const expensesHeadService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/expenses/head/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllExpensesHead: async () => {
        try {
            const response = await axiosInstance.get('/expenses/head/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default expensesHeadService