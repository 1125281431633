import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'; // Assuming you want to use `toast` for error messages
// import userService from "../../Services/userService"; // Assuming you have userService
import Cookies from 'js-cookie';
import userService from '../Services/userService';

const Login = () => {
    const navigate = useNavigate();

    const [data, setData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [regex] = useState({
        email: /.+@.+\..+/,
        password: /.{6,}/,
    });

    const handleChange = (value, field) => {
        setData((prevData) => ({ ...prevData, [field]: value }));
        setError('');
    };

    const handleSubmit = () => {
        setError('');
        let hasError = false;

        // Simple form validation
        if (!regex.email.test(data.email) || !regex.password.test(data.password)) {
            setError('Please enter valid credentials');
            toast.error('Invalid input');
            hasError = true;
        }

        if (!hasError) {
            setLoading(true);
            userService.login(data)
                .then((res) => {
                    const userProfile = {
                        id: res.user.id,
                        email: res.user.email,
                    };
                    localStorage.setItem('user', JSON.stringify(userProfile));
                    toast.success('Login Successfully');
                    setLoading(false);

                    Cookies.set('XIOQUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OIMSLQ1JFVC1LRVk=', res.token, {
                        secure: true,
                        sameSite: 'Lax',
                    });

                    navigate('/dashboard', { replace: true });
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.responseCode === 400) {
                        setError(error.message);
                    } else if (error.responseCode === 403) {
                        setError('Account Not Activated');
                    } else {
                        setError('Login failed. Please try again');
                    }
                });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <div className='flex items-center justify-center h-screen bg-slate-50'>
            <div className='bg-white w-96 border-[1px] items-center border-[#1EA56C] rounded-3xl shadow-lg shadow-gray-400 py-8'>
                <div className='flex flex-col mx-auto items-center h-full'>
                    <h1 className='text-[#1EA56C] text-4xl font-bold'>Login</h1>

                    <input
                        type='email'
                        placeholder='Enter Your Email'
                        value={data.email}
                        onChange={(e) => handleChange(e.target.value, 'email')}
                        onKeyDown={handleKeyDown}
                        className='border-b-2 border-gray-200 p-2 w-[90%] mt-10 focus:outline-none focus:border-[#1EA56C] font-normal'
                    />

                    <input
                        type='password'
                        placeholder='Enter Password'
                        value={data.password}
                        onChange={(e) => handleChange(e.target.value, 'password')}
                        onKeyDown={handleKeyDown}
                        className='border-b-2 border-gray-200 p-2 w-[90%] mt-5 focus:outline-none focus:border-[#1EA56C]'
                    />

                    {/* Show error message */}
                    {error && <p className='text-red-500 mt-3'>{error}</p>}

                    <button
                        className={`bg-[#1EA56C] border-[1px] hover:border-none text-white hover:text-white hover:bg-[#1EA56C] p-2 px-10 rounded-md mt-10 font-semibold shadow-md hover:shadow-slate-700 duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Login'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
