import React, { useEffect, useState } from "react";
import Loading from "../Modals/Loading";
import customerService from "../Services/customerService";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Components/DeleteModal";

const Users = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteID, setDeleteID] = useState();

  const initiateDelete = (id) => {
    setOpen(!open);
    setDeleteID(id);
  };
  const handleDelete = () => {
    setDeleting(true);
    customerService.delete(deleteID).then((res) => {
      setDeleting(false);
      setTrigger(!trigger);
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const response = await customerService.fetchAll();
        setUser(response.customers);
        setLoading(false);
      } catch { }
    };
    getUsers();
  }, [trigger]);

  const handleStatus = (id, s) => {
    let status = "active";
    if (s === "active") {
      status = "inactive";
    } else {
      status = "active";
    }
    customerService.status(id, { status: status }).then((res) => {
      setTrigger(!trigger);
    });
  };

  return (
    <div className="mx-16 my-4">
      <DeleteModal
        open={open}
        setOpen={setOpen}
        deleteFunction={handleDelete}
        deleting={deleting}
      />
      <div className=" flex flex-col">
        {loading ? (
          <div className="">
            <Loading />
          </div>
        ) : (
          <div className="mt-10">
            <div className="flex justify-end mt-4">
              <button
                onClick={() => {
                  navigate("/users/add");
                }}
                className="ml-3 py-2 my-4 px-4 bg-primary text-white rounded-lg hover:bg-white border-2 border-white duration-300 hover:border-primary hover:text-pretty hover:text-primary"
              >
                Add Customer
              </button>
            </div>
            <table className=" animate_animated animate_fadeIn">
              <thead className="bg-primary shadow-lg">
                <tr className="uppercase text-sm leading-normal w-full text-white">
                  <th className=" rounded-tl-lg p-2   text-center text-[13px] w-[1%]"></th>
                  <th className="  p-[2%] text-left text-[13px] w-[2%]">
                    Name
                  </th>
                  <th className="p-[2%] text-left text-[13px] w-[1%]">Email</th>
                  <th className="p-[2%] text-left text-[13px] w-[2%]">
                    Phone No
                  </th>
                  <th className="p-[2%] text-left text-[13px] w-[4%]">
                    Company Name
                  </th>
                  <th className=" p-[2%] text-left text-[13px] w-[2%]">
                    Designation
                  </th>
                  <th className="p-[2%] text-center text-[13px] w-[1%]">
                    Status
                  </th>
                  <th className="p-[2%] text-center text-[13px] w-[1%]">
                    Ledger
                  </th>
                  <th className=" p-5 text-center text-[13px] w-[1%]">
                    License
                  </th>
                  <th className=" rounded-tr-lg p-5 text-center text-[13px] w-[1%]">
                    Delete
                  </th>
                </tr>
              </thead>

              <tbody>
                {user?.map((data, index) => (
                  <tr
                    key={index}
                    className=" rounded-lg border-b border-gray-300 shadow-lg"
                  >
                    <th
                      className={` p-[2%] -mr-1 text-center rounded-l-lg w-[1%] bg-gray-50 ${data.customer_type == "B2B"
                        ? "text-blue-600"
                        : "text-red-600"
                        }`}
                    >
                      <div className={`font-bold text-xl py-4 `}>
                        {data.customer_type}
                      </div>
                    </th>
                    <th className="p-[2%] text-left text-[15px] text-primary font-bold w-[2%] bg-gray-50">
                      {data.first_name} {data.last_name}
                    </th>

                    <th className="p-[1%] text-left text-[11px] font-medium w-[1%] bg-gray-50">
                      {data.email}
                    </th>
                    <th className="p-[1%] text-left text-[11px] font-medium w-[2%] bg-gray-50">
                      {data.phone_number}
                    </th>
                    <th className="p-[1%] text-left text-[15px] font-light w-[4%] bg-gray-50">
                      {data.company_name}
                    </th>
                    <th className="p-[1%] text-left text-[14px] font-bold text-gray-600 w-[2%] bg-gray-50">
                      {data.designation}
                    </th>

                    <th className="p-[1%] text-left text-[12px] w-[1%] bg-gray-50">
                      <div
                        className={`cursor-pointer ${data.status === "active"
                          ? "bg-green-500"
                          : "bg-red-500"
                          } text-white rounded-lg text-center uppercase font-medium py-1`}
                        onClick={() => handleStatus(data.id, data.status)}
                      >
                        {data.status}
                      </div>
                    </th>
                    <th className="p-[1%] text-left text-[12px] w-[1%] bg-gray-50 ">
                      <button
                        className="p-[1%] rounded-md text-primary"
                        onClick={() => navigate(`/users/ledger?id=${data.id}`)}
                      >
                        View Ledger
                      </button>
                    </th>
                    <th className="p-[2%] text-center text-[14px] font-bold text-gray-600 w-[2%] bg-gray-50 ">
                      {data?.license ? (
                        <a
                          href={data.license}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          View
                        </a>
                      ) : (
                        <div className="pl-[15%]">-</div>
                      )}
                    </th>
                    <th className="p-[2%] text-left text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                      <button
                        onClick={() => initiateDelete(data.id)}
                        className="px-4 py-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="red"
                          class="size-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
