import React, { useEffect, useState } from 'react'
import { FaMinus, FaPlus } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import orderService from "../Services/orderService";
import supplierService from '../Services/supplierService';
import priceListService from "../Services/priceListService";
import purchaseService from '../Services/purchaseService';
import * as xlsx from "xlsx";
import toast from "react-hot-toast";
import moment from 'moment';

const CreatePurchaseOrder = () => {

    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [file, setFile] = useState(null);
    const [tab, setTab] = React.useState("Part Number");
    const [suppliers, setSuppliers] = useState([])
    const [concludedProducts, setConcludedProducts] = useState(0)
    const [products, setProducts] = useState([])
    const [initialData, setInitialData] = useState([])
    const [reference, setReference] = useState("")

    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const getOrder = async () => {
            setLoading(true);
            try {
                const response = await orderService.fetchByInvoice(params.get("invoice"));
                const updatedDetails = response.order.details.map((order) => ({
                    ...order,
                    availability: 'FULFILLED'
                }));
                // updatedDetails.map((d, index) => {
                //     let array = [...updatedDetails]
                //     array[index].supplier = d.supplier
                //     priceListService.single({ supplier: d.supplier, product: d.product }).then(res => {
                //         array[index].price = res.product.price
                //         // array[index].delivery = res.product.delivery
                //         // array[index].stock = res.product.quantity
                //         setData(array)
                //         setInitialData(array)
                //     })
                // })
                setData(updatedDetails);
                setInitialData(updatedDetails)
            } catch (e) {
                console.error("Failed to fetch Orders", e);
            }
            setLoading(false);
        };
        getOrder();
        const getSupplier = async () => {
            setLoading(true);
            try {
                const response = await supplierService.fetchAll();
                setSuppliers(response.suppliers);
            } catch (e) {
                console.error("Failed to fetch suppliers", e);
            }
            setLoading(false);
        };
        getSupplier();

    }, []);

    useEffect(() => {
        if (products.length > 0) {
            const updatedData = [...initialData].map((orderItem) => {
                const product = products.find((product) => product.vendor_code === orderItem.vendor_code);

                if (product) {
                    if (orderItem.quantity === product.quantity) {
                        return { ...orderItem, availability: "FULFILLED" };
                    } else if (product.quantity < orderItem.quantity) {
                        return { ...orderItem, availability: "LIMITED", quantity: product.quantity };
                    }
                }

                return { ...orderItem, availability: "NOTAVAILABLE", quantity: 0 };
            });
            setData(updatedData)
            setConcludedProducts(initialData.length - updatedData.length)
        }
    }, [products]);

    const handleSupplierChange = (index, value, product) => {
        let array = [...data]
        array[index].supplier = value
        priceListService.single({ supplier: value, product: product }).then(res => {
            array[index].price = res.product.price
            array[index].delivery = res.product.delivery
            array[index].stock = res.product.quantity
            setData(array)
        })
    }


    const handleAvailibilityChange = (index, value, product) => {
        let array = [...data]
        array[index].availibility = value
        setData(array)
    }

    const handleFileSelect = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                setFile(data);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    const ProceedFileRead = () => {
        if (file) {
            const workbook = xlsx.read(file, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            setProducts([...products, ...json]);
        } else {
            toast.error("Please upload any file!");
        }
    };

    const handlePurchaseCreate = async () => {
        function groupBySupplier(data) {
            return data.reduce((acc, item) => {
                const existingSupplier = acc.find(entry => entry.supplier === item.supplier);

                if (existingSupplier) {
                    existingSupplier.products.push(item);
                    existingSupplier.amount += parseFloat(item.price)*item.quantity;
                    existingSupplier.order_reference = item.order.toString();
                } else {
                    acc.push({
                        supplier: item.supplier,
                        products: [item],
                        amount: parseFloat(item.price).toFixed(2)*item.quantity,
                        paid_amount: 0,
                        payment_status: 'UNPAID',
                        order_reference: item.order.toString(),
                        purchase_date: date,
                        reference: reference
                    });
                }

                return acc;
            }, []);
        }

        let groupedData = groupBySupplier(data);
        async function submitPurchases(groupedData) {
            for (const payload of groupedData) {
                await purchaseService.submitPurchase(payload);
            }
            alert('Hurrah')
        }

        await submitPurchases(groupedData);
    }

    const initialDelete = (id) => {
        setData((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <div className="mx-16">
            {params.get("invoice") ? '' :
                <>
                    <div className=' text-center bg-teal-400 mx-96 p-1 rounded-lg'>   7 orders Required a purchase order  </div>
                    <div className=' text-center mt-3 text-md font-semibold text-red-600 hover:text-red-700 hover:cursor-pointer'> Create a purchase Order  </div>
                </>
            }
            <div className=" text-center font-extrabold text-4xl text-primary mt-5">
                Order  --to--  Purchase
            </div>
            <div className="bg-white shadow-xl pb-5   mx-20 mt-4 rounded-3xl flex flex-col items-center border border-gray-200">

                <div className=" flex justify-center ">
                    <div className={`mt-8 md:mt-8 lg:mt-8 px-4 md:px-0`}>
                        <div className="flex gap-6 lg:py-3 py-3">
                            <div className="flex flex-row w-[180px] mt-3 mb-3 items-center py-[5px] bg-gray-100 rounded-full text-sm px-5">
                                <input type="text" name="from" placeholder={"Reference"} value={reference} onChange={(e) => setReference(e.target.value)}
                                       className="bg-gray-100 w-full focus:outline-none border-none" />
                            </div>
                            <div className="flex flex-row w-[180px] mt-3 mb-3 items-center py-[9px] px-4 bg-gray-100 rounded-full text-sm">
                                <input type="date" name="from" value={date} onChange={(e) => setDate(e.target.value)}
                                       className="bg-gray-100 focus:outline-none border-none" />
                            </div>
                            <div className="flex flex-row w-full justify-end py-3 rounded-3xl">

                                <input
                                    onChange={(e) => handleFileSelect(e)}
                                    type="file"
                                    className="w-full py-1 text-md px-4 bg-gray-100 rounded-l-full focus:outline-none border border-gray-100 focus:border-gray-300
                    file:mr-4 file:py-[7px] file:px-6 file:rounded-full
                    file:border-0 file:text-sm file:font-semibold
                    file:bg-white file:text-black
                    hover:file:bg-primary hover:file:text-white"
                                />
                                <button
                                    onClick={() => ProceedFileRead()}
                                    className="bg-primary px-2 w-32 py-1 rounded-r-full text-white font-semibold text-lg"
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className={`text-center mt-5`}>{concludedProducts} Products Excluded</p>
            <div className="bg-white mt-4 rounded-xl flex flex-col items-center">
                <div className={`w-[100%] flex justify-between mt-10`}>
                    <table className=" w-[67%]">
                        <thead>
                            <tr className="bg-primary uppercase text-xs leading-normal w-full text-white">
                                <th className="py-[2%] rounded-tl-xl text-center text-[12px] w-[12%]">
                                    Supplier
                                </th>
                                <th className="py-[2%] text-center text-[12px] w-[8%]">
                                    Brand
                                </th>
                                <th className="py-[2%] text-center text-[12px] w-[8%]">
                                    Part No.
                                </th>

                                <th className="py-[2%]  text-center text-[12px] w-[14%]">
                                    Title
                                </th>
                                <th className="py-2  text-center text-[12px] w-[8%]">
                                    Quantity
                                </th>
                                <th className=" py-[2%] text-center text-[12px] w-[8%]">
                                    Weight (Kg)
                                </th>
                                <th className=" py-[2%] text-center text-[12px] w-[8%]">
                                    Total Weight
                                </th>
                                <th className="py-[2%] rounded-tr-xl  text-center text-[12px] w-[8%]">
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((data, index) => (
                                <tr
                                    key={index}
                                    className={`border-b border-gray-20`}
                                >
                                    <td className=" p-[2%] text-left font-bold text-[12px] w-[12%] bg-gray-50">
                                        {suppliers.filter(value => parseInt(value.id) === parseInt(data.supplier))[0]?.company}
                                        {/*<select*/}
                                        {/*    value={data.supplier}*/}
                                        {/*    name="supplier"*/}
                                        {/*    onChange={(e) => {*/}
                                        {/*        handleSupplierChange(index, e.target.value, data.product)*/}
                                        {/*    }}*/}
                                        {/*    className={`pl-4 ml-auto mr-[4%] mt-4 block py-[9px] w-36 text-sm bg-white rounded-[9px] border-[1px]`}*/}
                                        {/*    id="supplier mt-4"*/}
                                        {/*>*/}
                                        {/*    {suppliers.map((supplier) => (*/}
                                        {/*        <option key={supplier.id} value={supplier.id}>*/}
                                        {/*            {supplier.name} - {supplier.company}*/}
                                        {/*        </option>*/}
                                        {/*    ))}*/}
                                        {/*</select>*/}
                                    </td>

                                    <td className=" p-[2%] text-left font-bold text-[12px] w-[8%] bg-gray-50">
                                        {data.brand}
                                    </td>
                                    <td className=" p-[2%]  text-left text-[12px] w-[8%] bg-gray-50">
                                        {data.vendor_code}
                                    </td>

                                    <td className="p-[2%] text-left text-[12px] w-[14%] bg-gray-50">
                                        {data.title}
                                    </td>
                                    <td className="p-[2%] text-left text-[12px] w-[8%] bg-gray-50">
                                        {data.quantity}
                                    </td>
                                    <td className="p-[2%] text-left text-[12px] w-[8%] bg-gray-50">
                                        {data.weight}
                                    </td>
                                    <td className="p-[2%] text-left text-[12px] w-[8%] bg-gray-50">
                                        {parseFloat(data.quantity * data.weight).toFixed(2)}
                                    </td>
                                    <td className=" p-[2%] text-left text-[12px] w-[8%] bg-gray-50">
                                        <div className="flex flex-row gap-4">
                                            <div onClick={() => initialDelete(data.id)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="red"
                                                    class="size-5 hover:scale-110 duration-300 cursor-pointer"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className=" w-[30%]">
                        <thead>
                            <tr className="bg-gray-600 uppercase text-xs leading-normal w-full text-white">

                                <th className="text-center p-[4%] text-[12px] w-[4%] rounded-tl-lg">
                                    Delivery
                                </th>
                                {/*<th className="text-center p-[4%] text-[12px] w-[1%]">*/}
                                {/*    Stock*/}
                                {/*</th>*/}
                                <th className="text-center p-[4%] text-[12px] w-[1%]">
                                    Price
                                </th>
                                <th className="text-center p-[4%] text-[12px] w-[6%]">
                                    Total Price
                                </th>
                                <th className="text-center p-[2%] text-[12px] w-[4%] rounded-tr-lg">
                                    Availability
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((data, index) => (
                                <tr
                                    key={index}
                                    className={`border-b border-gray-20 ${data.availability === "FULFILLED" ? "bg-green-200" : data.availability === "LIMITED" ? "bg-orange-200" : "bg-red-200"}`}
                                >
                                    <td className="p-[2%] text-center text-[12px] w-[4%]">
                                        {data.delivery}
                                    </td>
                                    {/*<td className="p-[2%] text-center text-[12px] w-[1%] bg-gray-50">*/}
                                    {/*    {data?.stock}*/}
                                    {/*</td>*/}
                                    <td className="p-[2%] text-center text-[12px] w-[1%]">
                                        {data.price}
                                    </td>
                                    <td className="p-[2%] text-center font-bold text-[12px] w-[6%]">
                                        {parseFloat(data.price * data.quantity).toFixed(2)}
                                    </td>
                                    <td className="p-[2%] text-center text-[12px] w-[4%]">
                                        {data.availability}
                                    </td>
                                    {/*<td className=" text-left font-bold text-[12px] w-[4%] bg-gray-50">*/}
                                    {/*    <select*/}
                                    {/*        name="availibility"*/}
                                    {/*        value={data.availibility}*/}
                                    {/*        onChange={(e) => {*/}
                                    {/*            handleAvailibilityChange(index, e.target.value, data.product)*/}
                                    {/*        }}*/}
                                    {/*        className={`pl-4 ml-auto mr-[4%] mt-4 block py-[9px] w-36 text-sm bg-white rounded-[9px] border-[1px]`}*/}
                                    {/*        id="supplier mt-4"*/}
                                    {/*    >*/}
                                    {/*        <option key="FULFILLED" value="FULFILLED" className='p-2'>*/}
                                    {/*            Fulfilled*/}
                                    {/*        </option>*/}
                                    {/*        <option key="NOTAVAILABLE" value="NOTAVAILABLE" className='p-2'>*/}
                                    {/*            Not Available*/}
                                    {/*        </option>*/}
                                    {/*        <option key="LIMITED" value="LIMITED" className='p-2'>*/}
                                    {/*            Limited*/}
                                    {/*        </option>*/}
                                    {/*    </select>*/}
                                    {/*</td>*/}
                                </tr>))}
                        </tbody>
                    </table>
                </div>
                <button
                    onClick={() => handlePurchaseCreate()}
                    className="ml-3 mt-6 py-2 px-4 bg-primary text-white rounded-lg hover:bg-white hover:border-primary border-2 border-white hover:text-primary duration-300"
                >
                    Add Order
                </button>
            </div>
        </div>
    )
}

export default CreatePurchaseOrder
