import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import supplierService from "../Services/supplierService";
import toast from "react-hot-toast";
import DeleteModal from "../Components/DeleteModal";

const Suppliers = () => {
  const [data, setData] = useState([]);
  const [deleteID, setDeleteID] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState(false)

  const [deleteSupplier, setDeleteSupplier] = useState(false);

  const initiateDelete = (id) => {
    setOpen(!open);
    setDeleteID(id);
  };
  const handleDelete = () => {
    setDeleting(true);
    supplierService.delete(deleteID).then((res) => {
      setDeleting(false);
      setTrigger(!trigger)
    });
  };

  useEffect(() => {
    const getSuppliers = async () => {
      await supplierService.fetchAll().then((res) => {
        setData(res.suppliers);
      });
    };
    getSuppliers();
  }, [trigger]);

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
    // setTrigger(!trigger);
  };

  const navigate = useNavigate();
  return (
    <div className="mx-16 mt-10">
      <DeleteModal
        open={open}
        setOpen={setOpen}
        deleteFunction={handleDelete}
        deleting={deleting}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={() => {
            navigate("/suppliers/add");
          }}
          className="w-[180px] ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:shadow-lg duration-300 hover:shadow-gray-400"
        >
          Add Supplier
        </button>
      </div>
      <div className="mt-4">
        <table className="shadow-md animate__animated animate__fadeIn">
          <thead className="bg-primary">
            <tr className=" uppercase text-sm leading-normal w-full text-white">
              <th className="rounded-tl-lg p-[2%] text-center text-[13px] w-[2%]">
                Name
              </th>
              <th className="p-[2%] text-center text-[13px] w-[1%]">Company</th>
              <th className="p-[2%] text-center text-[13px] w-[2%]">Country</th>
              <th className="p-[2%] text-center text-[13px] w-[2%]">Ledger</th>
              <th className="p-[2%] rounded-tr-lg text-center text-[13px] w-[1%]">Delete</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((value, index) => (
              <tr key={index}>
                <td className="p-[2%] text-center text-[13px] w-[2%] bg-gray-50 border-b border-primary border-opacity-25 font-bold">
                  {value.name}
                </td>
                <td className="p-[2%] text-center text-[13px] w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                  {value.company} AED
                </td>
                <td className="p-[2%] text-center text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                  {value.country}
                </td>

                <td className="p-[2%] text-center text-[13px] w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                  <button
                    className="p-1 rounded-md text-primary"
                    onClick={() => navigate(`/suppliers/ledger?id=${value.id}`)}
                  >
                    View Ledger
                  </button>
                </td>
                <td className="p-[2%] text-center text-[13px] w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                  <button
                    onClick={() => initiateDelete(value.id)}
                    className="px-4 py-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="red"
                      class="size-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Suppliers;
