import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import expenseService from "../Services/expense";
import Loading from "../Components/Loading";
import { FaArrowLeft } from "react-icons/fa6";
import DeleteModal from "../Components/DeleteModal";

const Expenses = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filteredData, setFilteredData] = useState([])

  const [deleteID, setDeleteID] = useState();
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [totalExpense, setTotalExpense] = useState([])

  const initiateDelete = (id) => {
    setOpen(!open);
    setDeleteID(id);
  };

  useEffect(() => {
    expenseService.fetchAllBySpecificDate({ startDate: startDate ? startDate : null, endDate: endDate ? endDate : null }).then((res) => {
      setFilteredData(res.expenses)
      const totalAmount = res.expenses.reduce((total, item) => total + item.amount, 0);
      setTotalExpense(totalAmount)
    })
  }, [startDate, endDate])

  const handleDelete = () => {
    setDeleting(true);
    expenseService.delete(deleteID).then((res) => {
      setDeleting(false);
      setTrigger(!trigger)
    });
  };

  useEffect(() => {
    setLoading(true)
    expenseService.fetchAllExpenses().then((res) => {
      setData(res.expenses)
      setFilteredData(res.expenses)
      const totalAmount = res.expenses.reduce((total, item) => total + item.amount, 0);
      setTotalExpense(totalAmount)
      setLoading(false)
    })
  }, [trigger])

  return (
    <div>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        deleteFunction={handleDelete}
        deleting={deleting}
      />
      {
        loading ?
          <Loading /> :
          <div className="mt-10 mx-16 my-4">
            <div className="flex justify-between">
              <div className="my-1 cursor-pointer" onClick={() => navigate('/accounts')}><FaArrowLeft className="w-10 h-6" /></div>
              <div className="flex gap-6">
                <div className="flex flex-row items-center py-1 px-4 bg-gray-100 rounded-lg text-sm"><h1
                  className="mr-2">Start Date : </h1><input type="date" name="from" value={startDate} onChange={(e) => setStartDate(e.target.value)}
                    className="bg-gray-100 focus:outline-none border-none" />
                </div>
                <div className="flex flex-row items-center py-1 px-4 bg-gray-100 rounded-lg text-sm"><h1
                  className="mr-2">End Date : </h1><input type="date" name="from" value={endDate} onChange={(e) => setEndDate(e.target.value)}
                    className="bg-gray-100 focus:outline-none border-none" />
                </div>
              </div>
              <div className="flex justify-end gap-4 my-2 text-white">
                <div className="bg-primary  p-2 px-3 rounded-md cursor-pointer w-[180px] text-center"
                  onClick={() => navigate('/expenses/add')}
                >
                  Add Expense
                </div>
              </div>
            </div>
            <table className="mt-2 animate__animated animate__fadeIn">
              <thead className="bg-primary">
                <tr className=" uppercase text-sm leading-normal w-full text-white">
                  <th className="rounded-tl-xl text-center pl-5 text-[13px] w-[3%] p-[2%]">
                    Date
                  </th>
                  <th className="text-center pl-5 text-[13px] w-[2%]  border-b border-primary border-opacity-25p-[2%]">
                    Voucher Id
                  </th>
                  <th className="text-center pl-5 text-[13px] w-[2%]  border-b border-primary border-opacity-25p-[2%]">
                    Title
                  </th>
                  <th className=" text-center pl-5 text-[13px] w-[3%] p-[2%] ">
                    Account
                  </th>
                  <th className=" text-center pl-5 text-[13px] w-[2%]  border-b border-primary border-opacity-25p-[2%]">
                    Amount
                  </th>
                  <th className="text-center pl-5 text-[13px] w-[8%] p-[2%]">
                    Notes
                  </th>
                  <th className="rounded-tr-xl text-center pl-5 text-[13px] w-[1%] p-[2%]">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((expense, index) => (
                  <tr key={index} className="bg-gray-50">
                    <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                      {expense.date}
                    </td>
                    <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                      {expense?.voucher}
                    </td>
                    <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                      {expense.title}
                    </td>
                    <td className=" p-[2%] text-center text-[13px] w-[3%] border-b border-primary border-opacity-25">
                      {expense.account}
                    </td>
                    <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                      {expense.amount} AED
                    </td>
                    <td className=" p-[2%] text-center text-[13px] w-[8%]  border-b border-primary border-opacity-25">
                      {expense.note}
                    </td>
                    <td className="p-[2%] text-center text-[13px] w-[1%] border-b border-primary border-opacity-25">
                      <button
                        onClick={() => initiateDelete(expense.id)}
                        className="px-4"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="red"
                          class="size-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>Total Expense during the selected period : {totalExpense}</div>
          </div>
      }
    </div>
  );
};

export default Expenses;
