import React, { useState, useEffect } from 'react';
import * as xlsx from "xlsx";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { LoadingDots } from '../Components/LoadingDots';
import supplierService from '../Services/supplierService';
import priceListService from '../Services/priceListService';
import customerService from '../Services/customerService';

const ImportProducts = () => {
  const [products, setProducts] = useState([]);
  const [profit, setProfit] = useState(0)
  const [loading, setLoading] = useState(false);
  const [supplier, setSupplier] = useState("")
  const [customer, setCustomer] = useState("")
  const [isVisible, setIsVisible] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [customers, setCustomers] = useState([])
  const [date, setDate] = useState(null)
  const [profitInvisible, setProfitInvisible] = useState(false)

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  const navigate = useNavigate()

  useEffect(() => {
    const getSupplier = async () => {
      try {
        const response = await supplierService.fetchAll();
        setSuppliers(response.suppliers);
      } catch (e) {
        console.error("Failed to fetch suppliers", e);
      }
    };
    getSupplier();
    const getCustomer = async () => {
      try {
        const response = await customerService.fetchAll();
        setCustomers(response.customers);
      } catch (e) {
        console.error("Failed to fetch customers", e);
      }
    };
    getCustomer();
  }, [])

  const handleButtonClick = () => {
    const fileInput = document.getElementById('file-upload');
    fileInput.click();
  };

  const handleChange = ((e) => {
    setSupplier(e.target.value)
  })

  const handleCustomerClear = () => {
    setCustomer('');
    setProfitInvisible(false)
  }

  const handleProfitChange = ((e) => {
    if (e.target.value < 0) {
      return;
    }
    setProfit(e.target.value)
  })

  const handleCustomerChange = ((e) => {
    setProfit(0)
    setProfitInvisible(true)
    setCustomer(e.target.value)
  })

  useEffect(() => {
    if (!supplier || !date) {
      setIsVisible(false)
    }
    else {
      setIsVisible(true)
    }
  }, [supplier, date, profit])

  const handleFileSelect = (e) => {
    e.preventDefault();
    console.log(e.target.files)
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        ProceedFileRead(data)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const ProceedFileRead = (file) => {
    if (file) {
      const workbook = xlsx.read(file, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      const mappedData = json.map((item, index) => ({
        id: index + 1,
        vendor_code: typeof item.vendor_code === 'string' ? item.vendor_code.replace('-', '') : item.vendor_code,
        brand: item.brand || "",
        title: item.title || "",
        quantity: item.quantity || 0,
        weight: item.WEIGHT || 0,
        delivery: item.delivery || 0,
        supplier_code: item.SUPPLIER_CODE || 0,
        order_price: item.supplier_price,
        price: item.sale_price ? item.sale_price : item.supplier_price + (item.supplier_price * profit / 100),
        MIN_LOT: item.MIN_LOT || 0,
      }));
      setProducts(mappedData);

      const total = mappedData.reduce((acc, product) => {
        return {
          price: acc.price + parseFloat(product.price || 0),
          weight: acc.weight + parseFloat(product.weight || 0),
          quantity: acc.quantity + parseInt(product.quantity || 0),
          count: acc.count + 1,
        };
      },
        { price: 0, weight: 0, quantity: 0, count: 0 });

      setTotalPrice(total.price);
      setTotalWeight(total.weight);
      setTotalQuantity(total.quantity);
      setTotalProducts(total.count);

    } else {
      toast.error("Please upload a file!");
    }
  };

  const UploadProducts = () => {
    setLoading(true)
    // console.log(date, supplier)
    let data = { products: products, date, supplier, ...(customer && { customer }) }
    // console.log(data)
    console.log(data)
    priceListService.importProducts({ data }).then(response => {
      console.log('Data added')
      setLoading(false)
      if (response.responseCode === 200) {
        toast.success("Products Imported successfully!")
        navigate('/products', { replace: true })
      }
    })
  }

  return (
    <div className="mx-16 my-10">
      <div className="flex flex-col">
        <h1 className='text-center text-primary text-4xl font-semibold py-1 rounded-lg'>Add Price List</h1>
        <div className='flex flex-col items-center justify-center mt-10 border border-gray-300 shadow-xl shadow-gray-200 mb-10 rounded-xl p-4'>


          <p className=' text-gray-400 mt-2'>Upload the CSV file in the specified format to quickly add new products.</p>

          <div className='flex flex-col mt-6 gap-4'>
            <div className='flex gap-2'>
              <div className="flex flex-row w-[320px] items-center py-1 px-4 bg-gray-100 rounded-lg text-sm">
                <input type="date" name="from" value={date} onChange={(e) => setDate(e.target.value)}
                  className="bg-gray-100 focus:outline-none border-none" />
              </div>
              <select
                name='supplier'
                value={supplier}
                onChange={(e) => handleChange(e)}

                className='w-full py-2 bg-gray-200 rounded-md focus:outline-none px-2 cursor-pointer  '
              >
                <option value="" disabled> Select a supplier </option>
                {
                  suppliers?.map((supplier) => (
                    <option value={supplier?.id}> {supplier?.company}</option>
                  ))}
              </select>
              <select
                name='customer'
                value={customer}
                onChange={(e) => handleCustomerChange(e)}

                className='w-full py-2 bg-gray-200 rounded-md focus:outline-none px-2 cursor-pointer  '
              >
                <option value="" disabled> Select a customer </option>
                {
                  customers?.map((customer) => (
                    <option value={customer?.id}> {customer?.company_name}</option>
                  ))}
              </select>
              {
                customer ? <button className='px-2 py-1 rounded-md bg-red-600' onClick={() => handleCustomerClear()}>Clear customer</button> : ""
              }
              {
                profitInvisible === false ?
                  <div>
                    <input
                      type='number'
                      name='price'
                      value={profit}
                      onChange={(e) => { handleProfitChange(e) }}
                      className='w-[200px] py-4 px-4 bg-gray-200 rounded-md focus:outline-none  '
                      placeholder='Enter Profit Margin '
                    />
                  </div> : ""
              }
            </div>
            {
              isVisible && (
                <div className='flex gap-2 justify-center'>
                  {
                    !loading ?
                      <button onClick={() => handleButtonClick()} className='bg-primary px-5 py-2 rounded-lg text-white font-bold'>
                        Import Price List
                      </button> : ""
                  }
                </div>
              )
            }
          </div>




          <p className='text-black mt-5 flex w-full justify-center ml-2'><span className={`font-bold mr-2`}>{products.length === 0 ? "No " : products.length} </span> Products Found</p>

          {products.length !== 0 &&

            <button
              onClick={() => UploadProducts()}
              className="flex flex-row gap-3 bg-[#1EA56C] text-xl text-white items-center font-extrabold py-2 px-3 rounded-xl"
            >
              {
                loading ? <LoadingDots /> : ""
              }

              Upload {products.length} Products

            </button>

          }
        </div>

      </div>

      <input
        type="file"
        id="file-upload"
        onChange={(e) => handleFileSelect(e)}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default ImportProducts;
