import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { LoadingDots } from '../Components/LoadingDots';
import supplierService from '../Services/supplierService';
import { useNavigate } from 'react-router-dom';

const AddSupplier = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState({
        name: '',
        company: '',
        country: '',
        address: '',
        accountant: '',
        status: '',
        bank_account: '',
        other_account: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        company: false,
        country: false,
        address: false,
        accountant: false,
        status: false,
        bank_account: false,
        other_account: false
    });

    const [regex, setRegex] = useState({
        name: /.{3,50}$/,
        company: /.{3,50}$/,
        country: /.{1,50}$/,
        address: /.{1,50}$/,
        accountant: /.{1,50}$/,
        status: /.{1,50}$/,
        bank_account: /.{1,50}$/,
        other_account: /.{1,50}$/

    })


    const handleChange = (v, n) => {
        setData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const handleSubmit = () => {
        let hasError = false;
        Object.keys(data).map(v => {
            if (!regex[v]?.test(data[v])) {
                setErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })
        console.log(hasError)
        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else {
            setLoading(true)
            supplierService.create(data).then(res => {
                setLoading(false)
                toast.success('Supplier created successfully', { id: "2" })
                navigate('/suppliers')
            })
        }
    }

    return (
        <div className='mx-16 my-4'>
            <div className='flex justify-center'>
                <h1 className='text-2xl font-bold text-center mt-5 uppercase text-primary'>Add Supplier</h1>
            </div>

            <div className="flex flex-col justify-center space-y-6 px-36 py-10 bg-gray-100 rounded-3xl mt-6">
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-16">
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Name
                        </label>
                        <input
                            value={data.name}
                            name="name"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Account Manager"
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary 0 border-gray-200 focus:border-primary  w-full ${errors['name'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Company
                        </label>
                        <input
                            value={data.company}
                            name="company"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Rep Phone No."
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors['company'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-16">
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Country
                        </label>
                        <input
                            value={data.country}
                            name="country"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Your Country"
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors['country'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Address
                        </label>
                        <input
                            value={data.address}
                            name="address"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Enter Address"
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors['address'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-16">
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Accountant
                        </label>
                        <input
                            value={data.accountant}
                            name="accountant"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Enter Accountant"
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors['accountant'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Status
                        </label>
                        <input
                            value={data.status}
                            name="status"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Status Here"
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors['status'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-16">
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Bank Account
                        </label>
                        <input
                            value={data.bank_account}
                            name="bank_account"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Enter Bank Account"
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors['bank_account'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                    <div>
                        <label
                            className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                            Other Account
                        </label>
                        <input
                            value={data.other_account}
                            name="other_account"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder="Other Account"
                            className={`border-2 px-4 py-2 rounded-2xl focus:outline-primary hover:border-primary hover:border-opacity-50 hover:outline-primary border-gray-200 focus:border-primary  w-full ${errors['other_account'] ? "border-red-600" : "border-gray-300"}`}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        onClick={() => handleSubmit()}
                        className={`flex justify-center self-center mt-6 w-[25%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        <div className="mr-2">
                            ADD
                        </div>
                        {loading ? <LoadingDots /> : ""}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default AddSupplier;
