import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import { LoadingDots } from "../Components/LoadingDots";
import accountService from "../Services/accountService";
import { FaArrowLeft } from "react-icons/fa6";

const AddAccount = () => {
    const navigate = useNavigate()
    const [responseError, setResponseError] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        account_name: "",
        account_title: "",
        account_number: "",
        opening_balance: ""
    });

    const [errors, setErrors] = useState({
        account_name: false,
        account_title: false,
        account_number: false,
        opening_balance: false
    })

    const [regex, setRegex] = useState({
        account_name: /.{1,50}$/,
        account_title: /.{1,50}$/,
        account_number: /.{1,50}$/,
        opening_balance: /.{1,50}$/
    })

    const handleChange = (v, n) => {
        setResponseError('')
        if ((n === 'opening_balance') && v < 0) {
            return;
        }
        setData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const handleSubmit = () => {
        let hasError = false;
        Object.keys(data).map(v => {
            if (!regex[v]?.test(data[v])) {
                setErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })

        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else {
            setLoading(true)
            let formData = { ...data, current_balance: data.opening_balance, status: 'ACTIVE' }
            accountService.create(formData).then(res => {
                setLoading(false)
                toast.success('Account Added Successfully', { id: "2" })
                navigate('/accounts')
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <div className="flex justify-center min-h-screen py-2">
            <div className="w-[100%] md:w-[79%] lg:w-[90%] flex justify-center my-1 rounded-3xl px-10 md:px-32">
                <div className="w-full">
                    <div className="mb-6 mt-2">
                        <div className="my-1 cursor-pointer pt-16 pl-16" onClick={() => navigate('/accounts')}><FaArrowLeft className="w-10 h-6" /></div>
                        <h1 className="flex justify-center font-extrabold text-4xl text-primary">
                            Add Account
                        </h1>
                    </div>
                    <div className="flex flex-col justify-center space-y-6 px-16 py-10">
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Account Name
                                </label>
                                <input
                                    value={data.account_name}
                                    name="account_name"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Account Name"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['account_name'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Account Title
                                </label>
                                <input
                                    value={data.account_title}
                                    name="account_title"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Account Title"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['account_title'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Account Number
                                </label>
                                <input
                                    value={data.account_number}
                                    name="account_number"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Account Number"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['account_number'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Opening Balance
                                </label>
                                <input
                                    value={data.opening_balance}
                                    name="opening_balance"
                                    type="number"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Opening Balance"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['opening_balance'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-center flex-col">
                        <button
                            onClick={() => handleSubmit()}
                            className={`flex justify-center self-center w-[30%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            <div className="mr-2 uppercase">
                                Add Account
                            </div>
                            {loading ? <LoadingDots /> : ""}
                        </button>

                        <div className="mt-5"></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AddAccount;
