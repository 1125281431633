import axiosInstance from "./axiosInstance";

const customerService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/customers/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    login: async (payload) => {
        try {
            const response = await axiosInstance.post('/customers/login', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchSingle: async (email) => {
        try {
            const response = await axiosInstance.get('/customers/single/' + email);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/customers/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    updateProfile: async (id) => {
        try {
            const response = await axiosInstance.put('/customers/updateProfile/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    updatePassword: async (email) => {
        try {
            const response = await axiosInstance.put('/customers/changePassword/' + email);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    status: async (id, payload) => {
        try {
            const response = await axiosInstance.put('/customers/status/' + id, payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete(`/customers/delete/${id}`)
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    }
}

export default customerService