import axiosInstance from "./axiosInstance";

const quotationService = {
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/quotation/all');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    single: async (id) => {
        try {
            const response = await axiosInstance.get('/quotation/single/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    submitQuotation: async (payload) => {
        try {
            const response = await axiosInstance.post('/quotation/submit', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
}

export default quotationService;
