import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { LoadingDots } from '../Components/LoadingDots';
import transactionService from '../Services/transactionService';
import moment from 'moment';

const SupplierDebit = ({ isOpen, onClose, trigger, setTrigger, supplier }) => {

    const navigate = useNavigate()
    const [file, setFile] = useState()
    const [responseError, setResponseError] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        amount: "",
        date: "",
        note: ""
    });

    const [errors, setErrors] = useState({
        amount: false,
        date: false,
        note: false
    })

    const [regex, setRegex] = useState({
        amount: /.{1,50}$/,
        date: /.{1,50}$/,
        note: /.{1,50}$/
    })

    const handleChange = (v, n) => {
        if (n === 'date' && v > moment().format("YYYY-MM-DD")) {
            toast.error('Cannot select future date', { id: "1" });
            return;
        }
        setResponseError('')
        if (n === 'amount' && v < 0)
            return;
        setData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const handleSubmit = () => {
        let hasError = false;
        Object.keys(data).map(v => {
            if (!regex[v]?.test(data[v])) {
                setErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })

        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else {
            setLoading(true)
            let form = { ...data, supplier_id: supplier, transaction_type: "DEBIT" }
            console.log(form)
            transactionService.create(form).then(res => {
                setLoading(false)
                toast.success('Transaction Registered Successfully', { id: "2" })
                navigate('/suppliers')
            })
        }
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black  bg-opacity-50 w-[100%] mx-auto">
            <div className='bg-[#F3F4F6] h-[400px] rounded-md'>
                <div className='ml-auto p-1 px-2 rounded-md bg-red-500 w-14 m-1 cursor-pointer'
                    onClick={() => onClose()}
                >Close</div>
                <div className="mb-6 mt-4">
                    <h1 className="flex justify-center font-extrabold text-4xl text-primary">
                        Add Payment
                    </h1>
                </div>
                <div className="flex flex-col justify-center space-y-6 px-16 py-10">
                    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                        <div>
                            <label
                                className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                Amount
                            </label>
                            <input
                                value={data.amount}
                                name="amount"
                                type='number'
                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                className={`border-b-2 border-gray-200 p-1 focus:outline-none focus:border-primary  w-full ${errors['amount'] ? "border-red-600" : "border-gray-300"}`}
                            />
                        </div>
                        <div>
                            <label
                                className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                Date
                            </label>
                            <input
                                value={data.date}
                                name="date"
                                type='date'
                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                placeholder="Date"
                                className={`border-b-2 border-gray-200 p-1 focus:outline-none focus:border-primary  w-full ${errors['date'] ? "border-red-600" : "border-gray-300"}`}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-2 md:gap-20">
                        <div>
                            <label
                                className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                Notes
                            </label>
                            <input
                                value={data.notes}
                                name="note"
                                type='textarea'
                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                className={`border-b-2 border-gray-200 p-1 focus:outline-none focus:border-primary  w-full ${errors['note'] ? "border-red-600" : "border-gray-300"}`}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <button
                            onClick={() => handleSubmit()}
                            className={`flex justify-center self-center w-[30%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            <div className="mr-2">
                                Add Record
                            </div>
                            {loading ? <LoadingDots /> : ""}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplierDebit;
