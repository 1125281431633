import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import { LoadingDots } from "../Components/LoadingDots";
import accountService from "../Services/accountService";
import accountTransfersService from "../Services/accountsTransferService";
import { FaArrowLeft } from "react-icons/fa6";
import moment from "moment";

const AddJournalTransaction = () => {
    const navigate = useNavigate()
    const [responseError, setResponseError] = useState('')
    const [accounts, setAccounts] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        from_account: "",
        to_account: "",
        amount: "",
        date: "",
        note: ""
    });

    const [errors, setErrors] = useState({
        from_account: false,
        to_account: false,
        amount: false,
        date: false,
        note: false
    })

    const [regex, setRegex] = useState({
        from_account: /.{1,50}$/,
        to_account: /.{1,50}$/,
        amount: /.{1,50}$/,
        date: /.{1,50}$/,
        note: /.{1,50}$/
    })

    useEffect(() => {
        accountService.fetchAllActiveAccounts().then((res) => {
            setAccounts(res.accounts)
        })
    }, [])

    const handleChange = (v, n) => {
        if (n === 'date' && v > moment().format("YYYY-MM-DD")) {
            toast.error('Cannot select future date', { id: "1" });
            return;
        }
        if (n === 'amount' && v < 0) {
            return;
        }
        setResponseError('')
        setData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const handleSubmit = () => {
        let hasError = false;
        Object.keys(data).map(v => {
            if (!regex[v]?.test(data[v])) {
                setErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })

        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else {
            setLoading(true)
            accountTransfersService.create(data).then(res => {
                if (res.responseCode === 201) {
                    toast.error(res.message, { id: "3" });
                    setLoading(false)
                    return;
                }
                setLoading(false)
                toast.success('Journal Transaction Added Successfully', { id: "2" })
                navigate('/journals')
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <div className="flex justify-center min-h-screen py-2">
            <div className="w-[100%] md:w-[79%] lg:w-[90%] flex justify-center my-1 rounded-3xl px-10 md:px-32">
                <div className=" w-full">
                    <div className="mb-6 mt-10">
                        <div className="my-1 cursor-pointer pt-10 pl-16" onClick={() => navigate('/journals')}><FaArrowLeft className="w-10 h-6" /></div>
                        <h1 className="flex justify-center font-extrabold text-4xl text-primary">
                            Add Journal Transaction
                        </h1>
                    </div>
                    <div className="flex flex-col justify-center space-y-6 px-16 py-10">
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    From (Account) :
                                </label>
                                <select
                                    value={data.from_account}
                                    name="from_account"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}

                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full  ${errors['from_account'] ? "border-red-600" : "border-gray-300"}`}
                                    id="title mt-4"
                                >
                                    <option value="" selected disabled>
                                        Select From Account
                                    </option>
                                    {accounts.map((account) => (
                                        <option key={account.id} value={account.id}>
                                            {account.account_name} - {account.account_title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Amount
                                </label>
                                <input
                                    value={data.amount}
                                    name="amount"
                                    type="number"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['amount'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Account
                                </label>
                                <select
                                    value={data.to_account}
                                    name="to_account"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}

                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full  ${errors['to_account'] ? "border-red-600" : "border-gray-300"}`}
                                    id="title mt-4"
                                >
                                    <option value="" disabled>
                                        Select Beneficiary Account
                                    </option>
                                    {accounts.map((acc) => (
                                        <option key={acc.id} value={acc.id}>
                                            {acc.account_name} - {acc.account_title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Date
                                </label>
                                <input
                                    value={data.date}
                                    type="date"
                                    name="date"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="date"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['date'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>

                        <div>
                            <label
                                className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                Note
                            </label>
                            <input
                                value={data.note}
                                name="note"
                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                onKeyDown={handleKeyDown}
                                className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['note'] ? "border-red-600" : "border-gray-300"}`}
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-center flex-col">
                        <button
                            onClick={() => handleSubmit()}
                            className={`flex justify-center self-center w-[30%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            <div className="mr-2 uppercase">
                                Add
                            </div>
                            {loading ? <LoadingDots /> : ""}
                        </button>

                        <div className="mt-5"></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AddJournalTransaction;
