import axiosInstance from "./axiosInstance";

const dashboardService = {
    fetchStats: async () => {
        try {
            const response = await axiosInstance.get('/dashboard/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default dashboardService