import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { FaArrowLeft } from "react-icons/fa6";
import moment from 'moment';
import transactionService from "../Services/transactionService";

const Transactions = () => {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [filterMonth, setFilterMonth] = useState(moment().format('YYYY-MM'));
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [filter, setFilter] = useState('month')
    const [balance, setBalance] = useState(0)

    const calculateBalance = (transactions) => {
        return transactions.reduce((balance, item) => {
            if (item.transaction_type === 'CREDIT') {
                return balance + parseFloat(item.amount);
            } else if (item.transaction_type === 'DEBIT') {
                return balance - parseFloat(item.amount);
            }
            return balance;
        }, 0);
    };

    useEffect(() => {
        transactionService.fetchAllBySpecificDate({
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null
        }).then((res) => {
            setFilteredData(res.transactions);
            const totalBalance = calculateBalance(res.transactions);
            setBalance(totalBalance);
        })
    }, [startDate, endDate]);

    useEffect(() => {
        if (filter === 'month') {
            transactionService.fetchByMonth({ date: filterMonth })
                .then(res => {
                    setFilteredData(res.transactions);
                    const totalBalance = calculateBalance(res.transactions);
                    setBalance(totalBalance);
                })
                .catch(err => console.error('Error fetching by month:', err));
        } else {
            transactionService.fetchAllBySpecificDate({
                startDate: startDate ? startDate : null,
                endDate: endDate ? endDate : null
            }).then((res) => {
                setFilteredData(res.transactions);
                const totalBalance = calculateBalance(res.transactions);
                setBalance(totalBalance);
            })
        }
    }, [filter]);

    const handleMonthChange = (e) => {
        const selectedMonth = e.target.value;
        setFilterMonth(selectedMonth);
        transactionService.fetchByMonth({ date: selectedMonth })
            .then(res => {
                setFilteredData(res.transactions);
                const totalBalance = calculateBalance(res.transactions);
                setBalance(totalBalance);
            })
            .catch(err => console.error('Error fetching by month:', err));
    };

    useEffect(() => {
        setLoading(true);
        transactionService.fetchByMonth({ date: filterMonth }).then((res) => {
            setFilteredData(res.transactions);
            const totalBalance = calculateBalance(res.transactions);
            setBalance(totalBalance);
            setLoading(false);
        });
    }, []);


    return (
        <div>
            {
                loading ?
                    <Loading /> :
                    <div className="mt-10 mx-16 my-4">
                        <div className="flex justify-center items-center">
                            <h1 className="text-3xl">Transactions History</h1>
                        </div>
                        <div className="flex justify-between my-3">
                            <div className="flex w-[30%]">
                                <div className="my-1 cursor-pointer" onClick={() => navigate('/accounts')}><FaArrowLeft className="w-10 h-6" /></div>
                                <select
                                    name="filter"
                                    onChange={(e) => setFilter(e.target.value)}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-[60%] ? "border-red-600" : "border-gray-300"}`}
                                    id="title mt-4"
                                >
                                    <option value="date">
                                        Filter by date
                                    </option>
                                    <option value="month" selected>
                                        Filter by month
                                    </option>
                                </select>
                            </div>
                            {
                                filter === 'date' ?
                                    <div className="flex gap-6">
                                        <div className="flex flex-row items-center py-1 px-4 bg-gray-100 rounded-lg text-sm"><h1
                                            className="mr-2">Start Date : </h1><input type="date" name="from" value={startDate} onChange={(e) => setStartDate(e.target.value)}
                                                className="bg-gray-100 focus:outline-none border-none" />
                                        </div>
                                        <div className="flex flex-row items-center py-1 px-4 bg-gray-100 rounded-lg text-sm"><h1
                                            className="mr-2">End Date : </h1><input type="date" name="from" value={endDate} onChange={(e) => setEndDate(e.target.value)}
                                                className="bg-gray-100 focus:outline-none border-none" />
                                        </div>
                                    </div> :
                                    <div>
                                        <label htmlFor="monthFilter" className="mr-2">Filter by Month:</label>
                                        <input
                                            type="month"
                                            id="monthFilter"
                                            value={filterMonth}
                                            onChange={handleMonthChange}
                                            className="border p-1 rounded"
                                        />
                                    </div>
                            }
                        </div>
                        <table className=" animate__animated animate__fadeIn">
                            <thead className="bg-primary">
                                <tr className=" uppercase text-sm leading-normal w-full text-white">
                                    <th className="rounded-tl-xl text-center pl-5 text-[13px] w-[3%] p-[2%]">
                                        Date
                                    </th>
                                    <th className="text-center pl-5 text-[13px] w-[2%] p-[2%] ">
                                        Transaction Type
                                    </th>
                                    <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%] ">
                                        Amount
                                    </th>
                                    <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%] ">
                                        Note / Voucher
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.length > 0 ?
                                        <>
                                            {filteredData?.map((transaction, index) => (
                                                <tr key={index} className="bg-gray-50">
                                                    <td className="p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                                        {moment(transaction.date).format('YYYY-MM-DD')}
                                                    </td>
                                                    <td className="p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                                        {transaction.transaction_type ? transaction.transaction_type : "-"}
                                                    </td>
                                                    <td className="p-[2%] text-center text-[13px] w-[3%] border-b border-primary border-opacity-25">
                                                        {parseFloat(transaction.amount).toFixed(2)} AED
                                                    </td>
                                                    <td className="p-[2%] text-center text-[13px] w-[8%] border-b border-primary border-opacity-25">
                                                        {transaction?.note ? transaction.note : "-"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </> : "No Transactions to show"
                                }
                            </tbody>
                        </table>

                        <div>End Balance : {balance}</div>
                    </div>
            }
        </div>
    );
};

export default Transactions;
