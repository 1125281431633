import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { FaArrowLeft } from "react-icons/fa6";
import expensesHeadService from "../Services/expensesHead";

const ExpenseHead = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        expensesHeadService.fetchAllExpensesHead().then((res) => {
            setData(res.expenseHeads)
            setLoading(false)
        })
    }, [])

    return (
        <div>
            {
                loading ?
                    <Loading /> :
                    <div className="mt-10 mx-16 my-4">
                        <div className="flex justify-between">
                            <div className="my-1 cursor-pointer" onClick={() => navigate('/accounts')}><FaArrowLeft className="w-10 h-6" /></div>
                            <div className="flex justify-end gap-4 my-2 text-white">
                                <div className="bg-primary p-2 px-3 rounded-md cursor-pointer w-[180px] text-center"
                                    onClick={() => navigate('/expenses/head/add')}
                                >
                                    Add Expense Head
                                </div>
                            </div>
                        </div>
                        <table className=" animate__animated animate__fadeIn mt-2">
                            <thead className="bg-primary">
                                <tr className=" uppercase text-sm leading-normal w-full text-white">
                                    <th className="rounded-tl-xl text-center pl-5 text-[13px] w-[3%] p-[2%]">
                                        Expense Head
                                    </th>
                                    <th className="pl-5 text-[13px] w-[2%] p-[2%] text-center">
                                        Type
                                    </th>
                                    <th className="text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                        View Head Expenses
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((expense, index) => (
                                    <tr key={index} className="bg-gray-50">
                                        <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {expense.head_title}
                                        </td>
                                        <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {expense?.type}
                                        </td>
                                        <td className=" p-[2%] text-center text-[13px] w-[2%] text-blue-700  cursor-pointer border-b border-primary border-opacity-25"
                                            onClick={() => { navigate(`/expenses/expenseHead?id=${expense.id}`) }}
                                        >
                                            View
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    );
};

export default ExpenseHead;
