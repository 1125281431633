import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { FaArrowLeft } from "react-icons/fa6";
import DeleteModal from "../Components/DeleteModal";
import accountTransfersService from "../Services/accountsTransferService";

const JournalTransaction = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteID, setDeleteID] = useState();
    const [deleting, setDeleting] = useState(false);
    const [open, setOpen] = useState(false);
    const [trigger, setTrigger] = useState(false)

    const initiateDelete = (id) => {
        setOpen(!open);
        setDeleteID(id);
    };

    const handleDelete = () => {
        setDeleting(true);
        accountTransfersService.deleteAccountTransfer(deleteID).then((res) => {
            setDeleting(false);
            setTrigger(!trigger)
        });
    };

    useEffect(() => {
        setLoading(true)
        accountTransfersService.fetchAllaccountTransfers().then((res) => {
            setData(res.accountTransfers)
            setLoading(false)
        })
    }, [trigger])

    return (
        <div>
            <DeleteModal
                open={open}
                setOpen={setOpen}
                deleteFunction={handleDelete}
                deleting={deleting}
            />
            {
                loading ?
                    <Loading /> :
                    <div className="mt-10 mx-16 my-4">
                        <div className="flex justify-between">
                            <div className="my-1 cursor-pointer" onClick={() => navigate('/accounts')}><FaArrowLeft className="w-10 h-6" /></div>
                            <div className="flex justify-end gap-4 my-2 text-white">
                                <div className="bg-primary  p-2 px-3 rounded-md cursor-pointer w-[200px] text-center"
                                    onClick={() => navigate('/journal/add')}
                                >
                                    Add Journal Transaction
                                </div>
                            </div>
                        </div>
                        <table className=" animate__animated animate__fadeIn">
                            <thead className="bg-primary">
                                <tr className=" uppercase text-sm leading-normal w-full text-white">
                                    <th className="rounded-tl-xl text-center pl-5 text-[13px] w-[3%] p-[2%]">
                                        Date
                                    </th>
                                    <th className="text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                        To Account
                                    </th>
                                    <th className=" text-center pl-5 text-[13px] w-[3%] p-[2%] ">
                                        From Account
                                    </th>
                                    <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                        Amount
                                    </th>
                                    <th className=" text-center pl-5 text-[13px] w-[5%] p-[2%]">
                                        Note
                                    </th>
                                    <th className="rounded-tr-xl text-center pl-5 text-[13px] w-[1%] p-[2%]">
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((journal_transaction, index) => (
                                    <tr key={index} className="bg-gray-50">
                                        <td className="p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {journal_transaction.date}
                                        </td>
                                        <td className="p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {journal_transaction?.to_account_title}
                                        </td>
                                        <td className="p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {journal_transaction.from_account_title}
                                        </td>
                                        <td className="p-[2%] text-center text-[13px] w-[3%] border-b border-primary border-opacity-25">
                                            {parseFloat(journal_transaction.amount).toFixed(2)} AED
                                        </td>
                                        <td className="p-[2%] text-center text-[13px] w-[5%] border-b border-primary border-opacity-25">
                                            {journal_transaction?.note ? journal_transaction.note : "-"}
                                        </td>
                                        <td className="p-[2%] text-center text-[13px] w-[1%] border-b border-primary border-opacity-25">
                                            <button
                                                onClick={() => initiateDelete(journal_transaction.id)}
                                                className="px-4 py-1"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="2"
                                                    stroke="red"
                                                    class="size-4"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                    />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    );
};

export default JournalTransaction;
