import axiosInstance from "./axiosInstance";

const accountService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/accounts/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllaccounts: async () => {
        try {
            const response = await axiosInstance.get('/accounts/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllActiveAccounts: async () => {
        try {
            const response = await axiosInstance.get('/accounts/active/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    updateAccountStatus: async (payload) => {
        try {
            const response = await axiosInstance.put('/accounts/update/status', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default accountService