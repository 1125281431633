import React, { useEffect, useState } from 'react'
import OrderPayment from '../Modals/OrderPayment';
import moment from 'moment';
import Loading from '../Modals/Loading';
import orderService from '../Services/orderService';
import { useLocation, useNavigate } from 'react-router-dom';
import quotationService from "../Services/quotationService";
import * as xlsx from "xlsx";

const ViewQuotation = () => {
    const [quotation, setQuotation] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const getOrders = async () => {
            setLoading(true);
            try {
                const response = await quotationService.single(params.get("id"));
                setQuotation(response.quotation);
            } catch (e) {
                console.error("Failed to fetch Orders", error);
                setError(true); // to handle errors
            }
            setLoading(false);
        };
        getOrders();
    }, []);

    const totalQuantity = quotation?.details.reduce(
        (acc, item) => (acc + item.quantity),
        0
    );
    const totalWeight = quotation?.details.reduce(
        (acc, item) => (acc + parseFloat(item.weight || 0)),
        0
    );
    const totalPrice = quotation?.details.reduce(
        (acc, item) => (acc + item.quantity * parseFloat(item.price)),
        0
    );

    const downloadQuotation = () => {
        const summary = [
            { Weight: "Total Quantity", Delivery: totalQuantity },
            { Weight: "Total Weight", Delivery: totalWeight },
            { Weight: "Total Price", Delivery: totalPrice.toFixed(2) },
        ];

        const transformedData = quotation?.details.map((item) => ({
            "Vendor Code": item.vendor_code,
            Brand: item.brand,
            Title: item.title,
            "Quantity": item.quantity,
            Price: item.price,
            Weight: item.weight,
            Delivery: item.delivery,
        }));

        const blankRows = [{}, {}, {}];
        const fullData = [...transformedData, ...blankRows, ...summary];

        const worksheet = xlsx.utils.json_to_sheet(fullData);

        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Quotation");

        const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotation.xlsx");
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
    };

    return (
        <div className="my-10 mx-10 lg:mx-16">
            {loading ?
                <div className="mt-64">
                    <Loading />
                </div>
                :
                quotation ? (
                    <div>
                        <div className="flex flex-row justify-between border-b-2 border-gray-300 pb-4">
                            <div className="flex flex-row items-center">
                                <h1 className="font-bold text-2xl text-primary animate__animated animate__fadeInLeft">
                                    Quotation - {quotation.id}
                                </h1>
                            </div>
                            <div>
                                <button onClick={() => downloadQuotation()} className={`w-[180px] bg-primary text-white px-5 py-2 rounded-lg`}>
                                    Download Excel
                                </button>
                            </div>
                        </div>

                        <div className="grid grid-cols-4 w-[100%] mt-4">
                            <p className="flex flex-col font-semibold text-sm text-green-600">
                                Quotation Created At :{" "}
                                <span className="text-gray-500 font-medium">
                                    {moment(quotation?.created_at).format("LL")}
                                </span>
                            </p>
                            <p className="flex flex-col font-semibold text-sm text-green-600">
                                Quotation Date:{" "}
                                <span className="text-gray-500 font-medium">
                                    {moment(quotation?.quotation_date).format("LL")}
                                </span>
                            </p>
                        </div>

                        <h1 className="font-bold text-lg mt-8"> Customer Details </h1>
                        <div className="flex flex-row gap-8 mt-2">
                            <div>
                                <span className="block leading-none font-semibold">Customer Name</span>
                                <span className="text-sm leading-none font-medium text-gray-500"> {quotation.recipient} </span>
                            </div>

                            <div>
                                <span className="block leading-none font-semibold">Customer Email</span>
                                <span className="text-sm leading-none font-medium text-gray-500 "> {quotation.email} </span>
                            </div>

                            <div>
                                <span className="block leading-none font-semibold">Customer Phone</span>
                                <span className="text-sm leading-none font-medium text-gray-500"> {quotation.phone} </span>
                            </div>

                        </div>
                        <div className="mt-2">
                            <div className=' bg-white rounded-xl'>
                                <div className=' text-center pt-5 font-bold text-xl'> Quotation Details</div>
                                <div className=' px- py-1 '>
                                    <table className='  border-separate border-spacing-y-2'>
                                        <thead>
                                            <tr className="uppercase w-full text-white ">
                                                <th className="py-[2%] border-b-[2px] rounded-l-xl text-left border-white bg-primary text-white font-bold text-[12px] pl-4  w-[3%]">
                                                    Id
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white  text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Vendor Code
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Brand
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Weight
                                                </th>
                                                <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Price
                                                </th>
                                                <th className="py-[2%] border-b-[2px]  border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Quantity
                                                </th>
                                                <th className="py-[2%] border-b-[2px] rounded-r-xl border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                    Total Price
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {quotation.details && quotation.details.map((detail, index) => (
                                                <tr key={index} className='bg-gray-100 text-left text-sm'>
                                                    <td className='pl-4 w-[3%] py-2 rounded-l-xl font-light'>{detail.id}</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[11px] '>{detail.vendor_code}</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[12px] font-bold'>{detail.brand}</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[12px]'>{detail.weight}</td>
                                                    <td className='pl-4 w-[3%] py-2 text-[12px]'>{detail.price} AED</td>
                                                    <td className='pl-4 w-[3%] py-2  text-[12px]'>{detail.quantity}</td>
                                                    <td className='pl-4 w-[3%] py-2 rounded-r-xl text-[12px]'>{detail.quantity * detail.price} AED</td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className="flex justify-between mt-8">
                            <div>
                                <button onClick={() => navigate(`/quotation/get?id=${quotation.id}&customer=${quotation.customer}`, { state: { products: quotation.details } })} className={`bg-primary px-5 py-2 text-white rounded-md font-bold`}>Create Order</button>
                            </div>
                            <div className={`flex flex-row  bg-gray-100 px-8 py-2 rounded-xl`}>
                                <div className={`flex flex-row`}>
                                    <p className={`text-black font-light`}>Quotation Amount:</p>
                                    <p className={`ml-3 text-primary font-bold`}>{quotation.amount}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                ) : (
                    <div className="text-center text-4xl text-black">The Order you are looking for is currently unavailable </div>
                )
            }
        </div>
    )
}

export default ViewQuotation
