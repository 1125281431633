import axiosInstance from "./axiosInstance";

const expenseService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/expenses/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllExpenses: async () => {
        try {
            const response = await axiosInstance.get('/expenses/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete(`/expenses/delete/${id}`);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllExpensesByExpenseHead: async (head) => {
        console.log(head)
        try {
            const response = await axiosInstance.get(`/expenses/expenseHead/all/${head}`);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllBySpecificDate: async (payload) => {
        try {
            const response = await axiosInstance.post('/expenses/monthly/all', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default expenseService