import React, { useEffect, useState } from "react";
import { FaClock, FaShippingFast } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { LuPackageCheck } from "react-icons/lu";
import dashboardService from "../Services/dashboardService";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";
import purchaseService from "../Services/purchaseService";
import { MdRemoveRedEye } from "react-icons/md";
import orderService from "../Services/orderService";

const Home = () => {
  const [suppliersStats, setSuppliersStats] = useState([])
  const [customersStats, setCustomersStats] = useState([])
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getStats = async () => {
      try {
        setLoading(true);
        const response = await dashboardService.fetchStats();
        setStats(response.stats);
        setLoading(false);
      } catch { }
    };
    getStats();
    const getSuppliersStats = async () => {
      try {
        setLoading(true);
        const response = await purchaseService.fetchAllSuppliersStats();
        setSuppliersStats(response.purchases);
        setLoading(false);
      } catch { }
    };
    getSuppliersStats();

    const getCustomersStats = async () => {
      try {
        setLoading(true);
        const response = await orderService.fetchAllCustomersStats();
        setCustomersStats(response.orders);
        setLoading(false);
      } catch { }
    };
    getCustomersStats();

  }, []);
  return (
    <div className="mx-16 my-4">
      {loading === false ? (
        <>
          <div>
            <h1 className="font-bold text-4xl text-primary">Order Details</h1>
            <div className="grid grid-cols-4 gap-3 mt-4">
              <div className="bg-red-500 rounded-lg relative overflow-hidden">
                <h1 className="text-white font-semibold text-lg pt-3 pl-4">
                  Under Process
                </h1>
                <div className="flex justify-center items-center">
                  <h1 className="text-white font-bold text-6xl mt-6 mb-10">
                    {stats?.underprocess_order_count}
                  </h1>
                  <FaClock className="absolute right-0 bottom-0 text-white opacity-35 h-40 w-40 -mb-8" />
                </div>
              </div>

              <div className="bg-blue-600 rounded-lg relative overflow-hidden">
                <h1 className="text-white font-semibold text-lg pt-3 pl-4">
                  Confirmed
                </h1>
                <div className="flex justify-center items-center">
                  <h1 className="text-white font-bold text-6xl mt-6 mb-10">
                    {stats?.confirmed_order_count}
                  </h1>
                  <FaCheck className="absolute right-0 bottom-0 text-white opacity-35 h-40 w-40 -mb-8" />
                </div>
              </div>

              <div className="bg-yellow-400 rounded-lg relative overflow-hidden">
                <h1 className="text-white font-semibold text-lg pt-3 pl-4">
                  Ready For Shipment
                </h1>
                <div className="flex justify-center items-center">
                  <h1 className="text-white font-bold text-6xl mt-6 mb-10">
                    {stats?.readytodeliver_order_count}
                  </h1>
                  <FaShippingFast className="absolute right-0 bottom-0 text-white opacity-35 h-40 w-40 -mb-8" />
                </div>
              </div>

              <div className="bg-green-600 rounded-lg relative overflow-hidden">
                <h1 className="text-white font-semibold text-lg pt-3 pl-4">
                  Delivered
                </h1>
                <div className="flex justify-center items-center">
                  <h1 className="text-white font-bold text-6xl mt-6 mb-10">
                    {stats?.delivered_order_count}
                  </h1>
                  <LuPackageCheck className="absolute right-0 bottom-0 text-white opacity-35 h-40 w-40 -mb-8" />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 p-8 gap-4 bg-gray-100 rounded-lg shadow-lg mt-5">
            <div className="w-full bg-white rounded-md p-4">
              <h1 className="text-xl font-bold text-gray-700">Orders</h1>
              <h1 className="text-6xl font-bold text-center mt-3">
                {stats?.order_count}
              </h1>
              <div className="flex justify-end mt-3">
                <button
                  className="px-4 bg-primary py-2 font-semibold text-base text-white rounded-lg"
                  onClick={() => navigate("/order")}
                >
                  View
                </button>
              </div>
            </div>
            <div className="w-full bg-white rounded-md p-2">
              <h1 className="text-xl font-bold text-gray-700">Purchases</h1>
              <h1 className="text-6xl font-bold text-center mt-3">
                {stats?.purchase_count}
              </h1>
              <div className="flex justify-end mt-3">
                <button
                  className="px-4 bg-primary py-2 font-semibold text-base text-white rounded-lg"
                  onClick={() => navigate("/purchase")}
                >
                  View
                </button>
              </div>
            </div>
            <div className="w-full bg-white rounded-md p-2">
              <h1 className="text-xl font-bold text-gray-700">Quotations</h1>
              <h1 className="text-6xl font-bold text-center mt-3">
                {stats?.quotation_count}
              </h1>
              <div className="flex justify-end mt-3">
                <button
                  className="px-4 bg-primary py-2 font-semibold text-base text-white rounded-lg"
                  onClick={() => navigate("/quotation")}
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 mt-10 mb-20">
            <div>
              <h1 className="font-bold text-2xl text-primary mb-4 text-center">
                Suppliers
              </h1>
              <div className=" shadow-xl">
                {/* Add a scrollable wrapper */}
                <div className="max-h-[275px] overflow-y-auto">
                  <table className="w-full animate__animated animate__fadeIn">
                    <thead className="bg-primary">
                      <tr className="uppercase text-sm leading-normal text-white">
                        <th className="rounded-tl-xl bg-red-500 text-left pl-5 text-[11px] w-[2%] p-[2%]">
                          Company Name
                        </th>
                        <th className="text-center pl-5 text-[11px] w-[3%] p-[2%]">
                          Total Business
                        </th>
                        <th className="text-center pl-5 text-[11px] w-[2%] p-[2%]">
                          End Balance
                        </th>
                        <th className="rounded-tr-xl text-center pl-5 text-[11px] w-[4%] p-[2%]">
                          View
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {suppliersStats?.map((supplier, index) => (
                        <tr key={index} className="bg-gray-50">
                          <td className="p-[2%] text-center text-[13px] w-[2%] border-b border-r border-black border-opacity-25">
                            {supplier.company}
                          </td>
                          <td className="p-[2%] text-center text-[13px] w-[3%] bg-white border-b border-r border-black border-opacity-25">
                            {supplier.total_amount} AED
                          </td>
                          <td className="p-[2%] text-center text-[13px] w-[2%] border-b border-r border-black border-opacity-25">
                            {parseFloat(supplier.total_amount - supplier.total_paid_amount).toFixed(2)} AED
                          </td>
                          <td className="p-[2%] text-center text-[13px] w-[4%] border-b border-black border-opacity-25">
                            <MdRemoveRedEye className="mx-auto cursor-pointer" onClick={() => { navigate(`/suppliers/ledger?id=${supplier.supplier}`) }} />

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <h1 className="font-bold text-2xl text-primary mb-4 text-center">
                Customers
              </h1>
              <div className=" shadow-xl">
                {/* Add a scrollable wrapper */}
                <div className="max-h-[275px] overflow-y-auto">
                  <table className="w-full animate__animated animate__fadeIn">
                    <thead className="bg-primary">
                      <tr className="uppercase text-sm leading-normal text-white">
                        <th className="rounded-tl-xl bg-red-500 text-left pl-5 text-[11px] w-[2%] p-[2%]">
                          Company Name
                        </th>
                        <th className="text-center pl-5 text-[11px] w-[3%] p-[2%]">
                          Total Business
                        </th>
                        <th className="text-center pl-5 text-[11px] w-[2%] p-[2%]">
                          End Balance
                        </th>
                        <th className="rounded-tr-xl text-center pl-5 text-[11px] w-[4%] p-[2%]">
                          View
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customersStats?.map((customer, index) => (
                        <tr key={index} className="bg-gray-50">
                          <td className="p-[2%] text-center text-[13px] w-[2%] border-b border-r border-black border-opacity-25">
                            {customer.company_name}
                          </td>
                          <td className="p-[2%] text-center text-[13px] w-[3%] bg-white border-b border-r border-black border-opacity-25">
                            {customer.total_amount} AED
                          </td>
                          <td className="p-[2%] text-center text-[13px] w-[2%] border-b border-r border-black border-opacity-25">
                            {customer.total_amount - (customer.total_paid_amount ? customer.total_paid_amount : 0)} AED
                          </td>
                          <td className="p-[2%] text-center text-[13px] w-[4%] border-b border-black border-opacity-25">
                            <MdRemoveRedEye className="mx-auto cursor-pointer" onClick={() => { navigate(`/users/ledger?id=${customer.customer}`) }} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Home;
