import axiosInstance from "./axiosInstance";

const transactionService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/transactions/create', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/transactions/all');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchByMonth: async (payload) => {
        try {
            const response = await axiosInstance.post('/transactions/month/all', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchAllBySpecificDate: async (payload) => {
        try {
            const response = await axiosInstance.post('/transactions/specific/date/all', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default transactionService;
