import axiosInstance from "./axiosInstance";

const incomeService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/incomes/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete(`/incomes/delete/${id}`);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllIncomes: async () => {
        try {
            const response = await axiosInstance.get('/incomes/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllBySpecificDate: async (payload) => {
        try {
            const response = await axiosInstance.post('/incomes/monthly/all', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default incomeService