import React, { useState, useEffect } from "react";
import brandService from "../Services/brandService";
import Loading from "../Modals/Loading";

const CarMakers = () => {

  const [loading, setLoading] = useState(false)
  const [brand, setBrand] = useState()

  useEffect(() => {
    const getBrands = async () => {
      try {
        setLoading(true)
        const response = await brandService.fetchAllBrands()
        setBrand(response.brands)
        setLoading(false)
      }
      catch {

      }
    }
    getBrands()
  }, [])
  return (
    <div>
      {
        loading ? <div className=''>
          <Loading />
        </div> :


          <div className="mx-28 my-10">
            <div className=" flex flex-col">

              <div className="">

                <div className="bg-white">
                  <div className="text-center text-primary text-4xl font-semibold py-1 rounded-lg"> Top Brands </div>
                  <div className="mt-5">
                    <div className="grid grid-cols-4 justify-center gap-3 p-8">
                      {brand?.map((data, index) => (
                        data.brand_name && (



                          <div className="bg-gradient-to-t to-primary from-white from-90% bg-opacity-30 font-semibold py-5 text-center text-md rounded-xl shadow-lg hover:scale-105 duration-300 cursor-pointer"> {data.brand_name} </div>
                        )

                      ))}

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default CarMakers;
