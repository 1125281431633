import axiosInstance from "./axiosInstance";

const accountTransfersService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/accountTransfers/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllaccountTransfers: async () => {
        try {
            const response = await axiosInstance.get('/accountTransfers/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    deleteAccountTransfer: async (id) => {
        try {
            const response = await axiosInstance.delete(`/accountTransfers/delete/${id}`);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default accountTransfersService