import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import expenseService from "../Services/expense";
import Loading from "../Components/Loading";
import { FaArrowLeft } from "react-icons/fa6";

const ExpensesByExpenseHead = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        setLoading(true)
        expenseService.fetchAllExpensesByExpenseHead(params.get('id')).then((res) => {
            setData(res.expenses)
            setLoading(false)
        })
    }, [])

    return (
        <div>
            {
                loading ?
                    <Loading /> :
                    <div className="mt-10 mx-16 my-4">
                        <div className="flex justify-between">
                            <div className="my-1 cursor-pointer" onClick={() => navigate('/expenses/head')}><FaArrowLeft className="w-10 h-6" /></div>
                        </div>
                        <table className=" animate__animated animate__fadeIn">
                            <thead className="bg-primary">
                                <tr className=" uppercase text-sm leading-normal w-full text-white">
                                    <th className="rounded-tl-xl text-center pl-5 text-[13px] w-[3%] p-[2%]">
                                        Date
                                    </th>
                                    <th className="text-left pl-5 text-[13px] w-[2%] p-[2%]">
                                        Voucher Id
                                    </th>
                                    <th className="text-left pl-5 text-[13px] w-[2%] p-[2%]">
                                        Title
                                    </th>
                                    <th className=" text-center pl-5 text-[13px] w-[3%] p-[2%] ">
                                        Account
                                    </th>
                                    <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                        Amount
                                    </th>
                                    <th className="rounded-tr-xl text-center pl-5 text-[13px] w-[4%] p-[2%]">
                                        Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((expense, index) => (
                                    <tr key={index} className="bg-gray-50">
                                        <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {expense.date}
                                        </td>
                                        <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {expense?.voucher}
                                        </td>
                                        <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {expense.title}
                                        </td>
                                        <td className=" p-[2%] text-center text-[13px] w-[3%] border-b border-primary border-opacity-25">
                                            {expense.account}
                                        </td>
                                        <td className=" p-[2%] text-center text-[13px] w-[2%]  border-b border-primary border-opacity-25">
                                            {expense.amount} AED
                                        </td>
                                        <td className=" p-[2%] text-center text-[13px] w-[4%]  border-b border-primary border-opacity-25">
                                            {expense.note}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    );
};

export default ExpensesByExpenseHead;
