import React from 'react';

const OrderDetail = () => {
    const products = [
        {
            ID: 1,
            Product_Brand: "Brand A",
            Name: "Widget Pro",
            Vendor_Code: "A001",
            Quantity: 150,
            Weight: 1.5,
            Delivery_Days: 3,
            Supplier_Code: "SUP001",
            Price_AED: 25.00
        },
        {
            ID: 2,
            Product_Brand: "Brand B",
            Name: "Gadget Plus",
            Vendor_Code: "B002",
            Quantity: 80,
            Weight: 0.8,
            Delivery_Days: 5,
            Supplier_Code: "SUP002",
            Price_AED: 45.00
        },
        {
            ID: 3,
            Product_Brand: "Brand C",
            Name: "Tool X",
            Vendor_Code: "C003",
            Quantity: 200,
            Weight: 2.0,
            Delivery_Days: 2,
            Supplier_Code: "SUP003",
            Price_AED: 35.00
        },
        {
            ID: 4,
            Product_Brand: "Brand D",
            Name: "Device Y",
            Vendor_Code: "D004",
            Quantity: 50,
            Weight: 1.0,
            Delivery_Days: 7,
            Supplier_Code: "SUP004",
            Price_AED: 60.00
        },
        {
            ID: 5,
            Product_Brand: "Brand E",
            Name: "Appliance Z",
            Vendor_Code: "E005",
            Quantity: 30,
            Weight: 5.0,
            Delivery_Days: 4,
            Supplier_Code: "SUP005",
            Price_AED: 150.00
        },
        {
            ID: 6,
            Product_Brand: "Brand F",
            Name: "Gadget Elite",
            Vendor_Code: "F006",
            Quantity: 100,
            Weight: 1.2,
            Delivery_Days: 6,
            Supplier_Code: "SUP006",
            Price_AED: 30.00
        },
        {
            ID: 7,
            Product_Brand: "Brand G",
            Name: "Widget Ultra",
            Vendor_Code: "G007",
            Quantity: 75,
            Weight: 0.5,
            Delivery_Days: 1,
            Supplier_Code: "SUP007",
            Price_AED: 20.00
        },
        {
            ID: 8,
            Product_Brand: "Brand H",
            Name: "Device Pro Max",
            Vendor_Code: "H008",
            Quantity: 20,
            Weight: 3.0,
            Delivery_Days: 10,
            Supplier_Code: "SUP008",
            Price_AED: 120.00
        },
        {
            ID: 9,
            Product_Brand: "Brand I",
            Name: "Tool Mini",
            Vendor_Code: "I009",
            Quantity: 90,
            Weight: 0.4,
            Delivery_Days: 8,
            Supplier_Code: "SUP009",
            Price_AED: 15.00
        },
        {
            ID: 10,
            Product_Brand: "Brand J",
            Name: "Appliance Classic",
            Vendor_Code: "J010",
            Quantity: 10,
            Weight: 10.0,
            Delivery_Days: 14,
            Supplier_Code: "SUP010",
            Price_AED: 200.00
        }
    ];

    return (
        <div className="mx-16 my-4">
            <div className="flex flex-col">
                <div className="mt-10">
                    <table className="shadow-md animate__animated animate__fadeIn">
                        <thead className="bg-primary">
                            <tr className="uppercase text-sm leading-normal w-full text-white">
                                <th className="rounded-tl-lg p-[2%] text-left text-[13px] w-[20%]">Product Brand</th>
                                <th className="p-[2%] text-left text-[13px] w-[10%]">Name</th>
                                <th className="p-[2%] text-left text-[13px] w-[10%]">Vendor Code</th>
                                <th className="p-[2%] text-left text-[13px] w-[10%]">Quantity</th>
                                <th className="p-[2%] text-left text-[13px] w-[10%]">Weight</th>
                                <th className="p-[2%] text-left text-[13px] w-[10%]">Delivery Days</th>
                                <th className="rounded-tr-lg p-[2%] text-left text-[13px] w-[10%]">Price (AED)</th>
                                <th className="p-[2%] text-left text-[13px] w-[10%]">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <tr key={product.ID}>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        {product.Product_Brand}
                                    </td>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        {product.Name}
                                    </td>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        {product.Vendor_Code}
                                    </td>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        {product.Quantity}
                                    </td>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        {product.Weight} kg
                                    </td>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        {product.Delivery_Days}
                                    </td>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        {product.Price_AED}
                                    </td>
                                    <td className="p-[2%] text-left text-[13px] bg-gray-50 border-b border-primary border-opacity-25">
                                        <div className="flex flex-row gap-4">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="blue"
                                                className="size-5 hover:scale-110 duration-300"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                                />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="red"
                                                className="size-5 hover:scale-110 duration-300"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;
