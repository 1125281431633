import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import customerService from "../Services/customerService";
import uploadService from "../Services/uploadService";
import { LoadingDots } from "../Components/LoadingDots";

const AddCustomer = () => {
    const navigate = useNavigate()
    const [file, setFile] = useState()
    const [responseError, setResponseError] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        designation: "",
        company_name: "",
        warehouse_address: "",
        company_type: "",
        phone_number: "",
        rep_phone: "",
        // rep_email: "",
        office_address: "",
        password: "",
        email: "",
        // reference: "",
        license: "",
        confirm_password: ""
    });

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone_number: false,
        password: false,
        email: false,
        designation: false,
        company_type: false,
        company_name: false,
        warehouse_address: false,
        rep_phone: false,
        office_address: false,
        // rep_email: false,
        // reference: false,
        license: false,
        confirm_password: ""
    })

    const [regex, setRegex] = useState({
        first_name: /.{1,50}$/,
        last_name: /.{1,50}$/,
        phone_number: /^\+?[0-9]{7,15}$/,
        password: /.{1,50}$/,
        confirm_password: /.{1,50}$/,
        email: /^[\w\.\-]+@[a-zA-Z\d\-]+\.[a-zA-Z]{2,}$/,
        designation: /.{1,50}$/,
        company_type: /.{1,50}$/,
        company_name: /.{1,50}$/,
        warehouse_address: /.{1,50}$/,
        rep_phone: /^\+?[0-9]{7,15}$/,
        office_address: /.{1,50}$/,
        // rep_email: /.{1,50}$/,
        // reference: /.{1,50}$/,
        license: /.{1,50}$/
    })

    const handleChange = (v, n) => {
        setResponseError('')
        setData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const convertToBase64 = (e) => {
        const reader = new FileReader();
        if (e.target.files.length > 0) {
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                setErrors((errors) => ({ ...errors, license: false }));
                setFile(e.target.files[0]);
                setData((data) => ({ ...data, license: reader.result }));
            };
        }
    };

    const handleSubmit = () => {
        let hasError = false;
        Object.keys(data).map(v => {
            if (!regex[v]?.test(data[v])) {
                setErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })

        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else if (data.password !== data.confirm_password) {
            toast.error('Passwords donot Match', { id: "4" })
            setErrors(prevState => ({ ...prevState, password: true, confirm_password: true }))
            return;
        } else {
            setLoading(true)
            console.log(file)
            uploadService.single(file).then(res => {
                console.log(res)
                let form = { ...data, license: res.response.url }
                customerService.create(form).then(res => {
                    setLoading(false)
                    if (res.responseCode === 409) {
                        toast.error('Email already used')
                        setResponseError(res.message)
                    }
                    else {
                        toast.success('Customer Added Successfully', { id: "2" })
                        navigate('/users')
                    }
                })
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <div className="flex justify-center items-center min-h-screen py-16">
            <div className="w-[100%] md:w-[79%] lg:w-[90%] flex justify-center self-center items-center rounded-3xl px-10 md:px-32">
                <div className=" w-full">
                    <div className="mb-6 mt-10">
                        <h1 className="flex justify-center font-extrabold text-4xl text-primary">
                            Add Customer
                        </h1>
                    </div>
                    <div className="flex flex-col justify-center space-y-6 px-16 py-10">
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    First Name
                                </label>
                                <input
                                    value={data.first_name}
                                    name="first_name"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="First Name"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['first_name'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Last Name
                                </label>
                                <input
                                    value={data.last_name}
                                    name="last_name"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Last Name"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['last_name'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Designation
                                </label>
                                <input
                                    value={data.designation}
                                    name="designation"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Designation"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['designation'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Company Name
                                </label>
                                <input
                                    value={data.company_name}
                                    name="company_name"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Company Name"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['company_name'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Warehouse Address
                                </label>
                                <input
                                    value={data.warehouse_address}
                                    name="warehouse_address"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Warehouse Address"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['warehouse_address'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Company Type
                                </label>
                                <input
                                    value={data.company_type}
                                    name="company_type"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Company type"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['company_type'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Phone Number
                                </label>
                                <input
                                    value={data.phone_number}
                                    name="phone_number"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Phone Number"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['phone_number'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Rep Phone
                                </label>
                                <input
                                    value={data.rep_phone}
                                    name="rep_phone"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Rep Phone No."
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['rep_phone'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            {/* <div>
                                <label className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Rep Email
                                </label>
                                <input
                                    value={data.rep_email}
                                    name="rep_email"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Rep Email"
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['rep_email'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div> */}
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Email
                                </label>
                                <input
                                    value={data.email}
                                    name="email"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Email"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['email'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Office Address
                                </label>
                                <input
                                    value={data.office_address}
                                    name="office_address"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Office Address"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['office_address'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        {/* <div className="grid grid-cols-2 gap-20"> */}
                        {/* <div>
                                <label className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Reference
                                </label>
                                <input
                                    value={data.reference}
                                    name="reference"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Reference"
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['reference'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div> */}
                        {/* </div> */}
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Password
                                </label>
                                <input
                                    value={data.password}
                                    name="password"
                                    type="password"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Password"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['password'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Confirm Password
                                </label>
                                <input
                                    value={data.confirm_password}
                                    name="confirm_password"
                                    type="password"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    placeholder="Confirm Password"
                                    onKeyDown={handleKeyDown}
                                    className={`border-b-2 border-gray-200 focus:outline-none focus:border-primary  w-full ${errors['confirm_password'] ? "border-red-600" : "border-gray-300"}`}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-20">
                            <div>
                                <label
                                    className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                    Trading License
                                </label>
                                <input
                                    type="file"
                                    placeholder="Enter Part No."
                                    onChange={(e) => convertToBase64(e)}
                                    onKeyDown={handleKeyDown}
                                    className={`w-full py-1 text-sm px-2 bg-gray-100 rounded-sm focus:outline-none border ${errors['license'] ? "border-red-600" : "border-gray-300"} focus:border-gray-300
                                file:mr-4 file:py-[7px] file:px-6 file:rounded-sm
                                file:border-0 file:text-[12px] file:font-semibold
                                file:bg-white file:text-black
                                hover:file:bg-primary hover:file:text-white`}
                                />
                            </div>
                        </div>
                        <div>
                            {responseError ?
                                <div className="text-red-600 text-sm">
                                    {responseError}
                                </div> : ""
                            }
                        </div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <button
                            onClick={() => handleSubmit()}
                            className={`flex justify-center self-center w-[30%] rounded-xl py-2 shadow-lg text-sm font-bold text-white bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            <div className="mr-2">
                                ADD
                            </div>
                            {loading ? <LoadingDots /> : ""}
                        </button>

                        <div className="mt-5"></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AddCustomer;
