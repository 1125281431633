import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import productService from "../Services/productService";
import * as xlsx from "xlsx";
import toast from "react-hot-toast";
import { FaArrowRightLong, FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { RiFileExcel2Line } from "react-icons/ri";
import orderService from "../Services/orderService";
import Loading from "../Components/Loading";
import customerService from "../Services/customerService";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import priceListService from "../Services/priceListService";
import moment from "moment";

const QuotationOrder = () => {

    const location = useLocation();
    const order_products = location.state.products;
    const params = new URLSearchParams(location.search)
    const customer = params.get("customer")

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState(order_products.map(product => ({
        ...product,
        show: true,
        qty: product.quantity
    })));
    const [products, setProducts] = useState([]);
    const [file, setFile] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState({ id: customer })
    const [orderDate, setOrderDate] = useState('')
    const [discount, setDiscount] = useState(false)
    const [concludedProducts, setConcludedProducts] = useState(0)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [reference, setReference] = useState("")

    const [orderData, setOrderData] = useState({
        customer: "",
        name: "",
        email: "",
        phone: "",
        amount: 0,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (products.length !== 0) {
            const getProducts = async () => {
                try {
                    setInitialData([])
                    setLoading(true);
                    let parts = [];
                    let quantities = {};
                    products.map((single) => {
                        parts.push(single["Part Number"]);
                        quantities[single["Part Number"]] = single.QTY;
                    });
                    let response;
                    if (discount) {
                        response = await productService.searchByVendorAndCustomer(parts.toString(), selectedCustomer.id);
                    } else {
                        response = await productService.searchByPart(parts.toString());
                    }
                    setConcludedProducts(order_products.length - response.products.length)
                    setInitialData((d) => [
                        ...d,
                        ...response.products.map((item) => ({
                            ...item,
                            qty:
                                quantities[item.vendor_code],
                            show: true,
                        })),
                    ]);
                    setLoading(false);
                    setProducts([]);
                } catch (e) { }
            };
            getProducts();
        }
        console.log(products)
    }, [products]);

    useEffect(() => {
        const checkDiscountForCustomer = async () => {
            try {
                const response = await priceListService.priceListByCustomer(selectedCustomer.id);
                setOrderDate(response.priceList.date);
            } catch (e) {
                console.error("Failed to fetch List", e);
            }
        };
        checkDiscountForCustomer();
    }, [selectedCustomer])

    useEffect(() => {
        console.log(orderDate)
    }, [orderDate])

    useEffect(() => {
        setData(
            initialData.reduce((acc, current) => {
                const isDuplicate = acc.some((item) => item.id === current.id && item.supplier === current.supplier);
                if (!isDuplicate) {
                    acc.push(current);
                }
                return acc;
            }, [])
        );
    }, [initialData]);

    useEffect(() => {
        if (data.length !== 0) {
            setShowTable(true);
        }
    }, [data]);

    const handleFileSelect = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                setFile(data);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    const ProceedFileRead = () => {
        if (file) {
            const workbook = xlsx.read(file, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            setProducts(json);
        } else {
            toast.error("Please upload any file!");
        }
    };

    const totalQuantity = data.reduce(
        (acc, item) => (item.show ? acc + item.qty : acc),
        0
    );
    const totalWeight = data.reduce(
        (acc, item) => (item.show ? acc + parseFloat(item.weight || 0) : acc),
        0
    );
    const totalPrice = data.reduce(
        (acc, item) => (item.show ? acc + item.qty * parseFloat(item.price).toFixed(2) : acc),
        0
    );

    const proceedToCheckout = () => {
        let submit = { ...orderData };
        const transformedProducts = data.map((item) => ({
            brand: item.brand,
            created_at: item.created_at,
            delivery: item.delivery,
            product: item.product,
            price: parseFloat(item.price).toFixed(2),
            quantity: item.qty,
            show: item.show,
            supplier_code: item.supplier_code,
            supplier: item.supplier,
            title: item.title,
            vendor_code: item.vendor_code,
            weight: item.weight,
        }));

        submit.amount = totalPrice;
        submit.email = selectedCustomer?.email;
        submit.phone = selectedCustomer?.phone_number
        submit.customer = selectedCustomer?.id
        submit.order_date = date
        submit.reference = reference
        submit.recipient = selectedCustomer?.first_name + selectedCustomer?.last_name
        console.log(submit?.recipent)
        submit.products = transformedProducts.filter((value) => value.show);
        console.log(submit);
        orderService.submitOrder(submit).then((response) => {
            if (response.responseCode === 200) {
                navigate("/order");
            }
        });
    };

    const handleIncrement = (v, id) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === id && item.quantity >= v && v > 0
                    ? { ...item, qty: v }
                    : item
            )
        );
    };

    const handleDecrement = (v, id) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === id && item.quantity >= v && v > 0
                    ? { ...item, qty: v }
                    : item
            )
        );
    };

    const initialDelete = (id) => {
        setData((prev) => prev.filter((item) => item.id !== id));
    };

    const downloadQuotation = () => {
        const summary = [
            { Weight: "Total Quantity", Delivery: totalQuantity },
            { Weight: "Total Weight", Delivery: totalWeight },
            { Weight: "Total Price", Delivery: totalPrice.toFixed(2) },
        ];

        const transformedData = data.map((item) => ({
            "Vendor Code": item.vendor_code,
            Brand: item.brand,
            Title: item.title,
            "Ordered Quantity": item.qty,
            Price: item.price,
            Weight: item.weight,
            Delivery: item.delivery,
        }));

        const blankRows = [{}, {}, {}];
        const fullData = [...transformedData, ...blankRows, ...summary];

        const worksheet = xlsx.utils.json_to_sheet(fullData);

        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Quotation");

        const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotation.xlsx");
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
    };

    return (
        <div className="py-20 min-h-screen">
            <div className=" text-center font-extrabold text-4xl text-primary">
                Quotation  --to--  Order
            </div>
            <div className="bg-white shadow-xl pb-5   mx-20 mt-4 rounded-3xl flex flex-col items-center border border-gray-200">

                <div className=" flex justify-center ">
                    <div className={`mt-8 md:mt-8 lg:mt-8 px-4 md:px-0`}>
                        <div className="flex flex-row lg:py-3 py-3">
                            <div className="flex flex-row w-[180px] mt-3 mb-3 items-center py-[5px] bg-gray-100 rounded-full text-sm mr-3 px-5">
                                <input type="text" name="from" placeholder={"Reference"} value={reference} onChange={(e) => setReference(e.target.value)}
                                       className="bg-gray-100 w-full focus:outline-none border-none" />
                            </div>
                            <div className="flex flex-row w-[180px] mt-3 mb-3 items-center py-[5px] px-4 bg-gray-100 rounded-full text-sm mr-3">
                                <input type="date" name="from" value={date} onChange={(e) => setDate(e.target.value)}
                                       className="bg-gray-100 focus:outline-none border-none" />
                            </div>
                            <div className="flex flex-row w-full justify-end py-3 rounded-3xl">
                                <input
                                    onChange={(e) => handleFileSelect(e)}
                                    type="file"
                                    className="w-full py-1 text-md px-4 bg-gray-100 rounded-l-full focus:outline-none border border-gray-100 focus:border-gray-300
                    file:mr-4 file:py-[7px] file:px-6 file:rounded-full
                    file:border-0 file:text-sm file:font-semibold
                    file:bg-white file:text-black
                    hover:file:bg-primary hover:file:text-white"
                                />
                                <button
                                    onClick={() => ProceedFileRead()}
                                    className="bg-primary px-2 w-32 py-1 rounded-r-full text-white font-semibold text-lg"
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <p className={`text-center mt-5`}>{concludedProducts} Products Excluded</p>
            {loading ? (
                <div className="flex items-center justify-center py-8">
                    <Loading />
                </div>
            ) : (
                <>
                    {showTable && (
                        <div className="flex flex-col w-full px-20 gap-2 mt-5">
                            <div className={`w-[100%] flex mt-10`}>
                                <table className=" w-full">
                                    <thead>
                                    <tr className="bg-primary uppercase text-xs leading-normal w-full text-white">
                                        <th className=" py-2  rounded-tl-xl  text-center text-[12px] w-[8%]">
                                            Qty
                                        </th>
                                        <th className="py-6 text-center text-[12px] w-[8%]">
                                            Brand
                                        </th>
                                        <th className="py-2 text-center text-[12px] w-[8%]">
                                            Part No.
                                        </th>

                                        <th className="py-2  text-center text-[12px] w-[14%]">
                                            Title
                                        </th>
                                        <th className=" py-2 text-center text-[12px] w-[8%]">
                                            Stock
                                        </th>
                                        <th className=" py-2 text-center text-[12px] w-[8%]">
                                            Weight (Kg)
                                        </th>
                                        <th className=" py-2 text-center text-[12px] w-[8%]">
                                            Total Weight
                                        </th>
                                        <th className="py-2 text-center text-[12px] w-[8%]">
                                            Delivery
                                        </th>
                                        <th className="py-2 text-center text-[12px] w-[8%]">
                                            Price
                                        </th>
                                        <th className="py-2 text-center text-[12px] w-[8%]">
                                            Total Price
                                        </th>
                                        <th className="py-2 rounded-tr-xl  text-center text-[12px] w-[8%]">
                                            Delete
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((data, index) => (
                                        <tr
                                            key={index}
                                            className={`border-b border-gray-200  ${data.show ? "" : "hidden"
                                            }`}
                                        >
                                            <td className="p-[2%] text-center text-[12px] w-[8%] bg-white">
                                                <div className="flex rounded-md justify-center items-center ">
                                                    <div className="flex flex-row items-center gap-1">
                                                        <div>
                                                            <FaMinus
                                                                className="cursor-pointer p-1 h-5 w-5 bg-red-500 rounded-md text-white"
                                                                onClick={() =>
                                                                    handleDecrement(data.qty - 1, data.id)
                                                                }
                                                            />
                                                        </div>
                                                        <p
                                                            className={`w-[20px] text-center font-extrabold rounded-md`}
                                                        >
                                                            {data.qty}
                                                        </p>
                                                        <div>
                                                            <FaPlus
                                                                className="cursor-pointer p-1 h-5 w-5 bg-green-500 rounded-md text-white"
                                                                onClick={() =>
                                                                    handleIncrement(data.qty + 1, data.id)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className=" p-[2%] text-left font-bold text-[12px] w-[8%] bg-white">
                                                {data.brand}
                                            </td>
                                            <td className=" p-[2%]  text-left text-[12px] w-[8%] bg-white">
                                                {data.vendor_code}
                                            </td>

                                            <td className="p-[2%] text-left text-[12px] w-[14%] bg-white">
                                                {data.title}
                                            </td>
                                            <td className="p-[2%] text-left text-[12px] w-[8%] bg-white">
                                                {data.quantity}
                                            </td>
                                            <td className="p-[2%] text-left text-[12px] w-[8%] bg-white">
                                                {data.weight}
                                            </td>
                                            <td className="p-[2%] text-left text-[12px] w-[8%] bg-white">
                                                {parseFloat(data.quantity * data.weight).toFixed(2)}
                                            </td>
                                            <td className="p-[2%] text-left text-[12px] w-[8%] bg-white">
                                                {data.delivery}
                                            </td>
                                            <td className="p-[2%] text-left text-[12px] w-[8%] bg-white">
                                                <input className={`w-16 text-center border-gray-500 py-2 bg-gray-100 rounded-lg`} value={data.price} />
                                            </td>
                                            <td className="p-[2%] text-left font-bold text-[12px] w-[8%] bg-white">
                                                {parseFloat(data.price * data.qty).toFixed(2)}
                                            </td>
                                            <td className=" p-[2%] text-left text-[12px] w-[8%] bg-white">
                                                <div className="flex flex-row gap-4">
                                                    <div onClick={() => initialDelete(data.id)}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="red"
                                                            class="size-5 hover:scale-110 duration-300 cursor-pointer"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className=" flex flex-col md:flex-row self-end justify-between w-[100%] p-5 mt-4 rounded-xl bg-white">
                                <div className={`flex flex-row`}>
                                    <div className=" flex flex-row md:items-center gap-3 justify-center bg-primary py-8 rounded-2xl px-10">
                                        <div className="w-[120px] flex flex-col text-white text-xl font-bold items-center">
                                            <span className="text-white text-sm font-medium text-center">
                                                Total Price
                                            </span>
                                            <div className="flex justify-center mt-1">
                                                <h1 className="flex justify-center text-center font-extrabold text-white text-2xl">
                                                    {parseFloat(totalPrice).toFixed(2)}
                                                </h1>
                                                <h1 className="ml-1 font-light text-gray-300 text-[10px]">
                                                    AED
                                                </h1>
                                            </div>
                                        </div>
                                        <div className={`h-[60px] bg-white w-[1px]`} />
                                        <div className="w-[120px] flex flex-col text-white text-xl font-bold items-center">
                                            <span className="text-white text-sm font-medium text-center">
                                                Total Weight
                                            </span>
                                            <div className="flex justify-center mt-1">
                                                <h1 className="flex justify-center text-center font-extrabold text-white text-2xl">
                                                    {parseFloat(totalWeight).toFixed(2)}
                                                </h1>
                                                <h1 className="ml-1 font-light text-gray-300 text-[10px]">
                                                    KG
                                                </h1>
                                            </div>
                                        </div>
                                        <div className={`h-[60px] bg-white w-[1px]`} />
                                        <div className="w-[120px] flex flex-col text-white text-xl font-bold items-center">
                                            <span className="text-white text-sm font-medium text-center">
                                                Total Quantity
                                            </span>
                                            <div className="flex justify-center mt-1">
                                                <h1 className="flex justify-center text-center font-extrabold text-white text-2xl">
                                                    {parseFloat(totalQuantity).toFixed(0)}
                                                </h1>
                                                <h1 className="ml-1 font-light text-gray-300 text-[10px]">
                                                    PCS
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => downloadQuotation()}
                                        className="cursor-pointer ml-5 flex flex-row md:items-center gap-3 justify-center bg-secondary py-8 rounded-2xl px-5"
                                    >
                                        <div className="w-[150px] flex flex-col text-white text-xl font-bold items-center">
                                            <RiFileExcel2Line
                                                className={`text-white text-[32px]`}
                                            />
                                            <span className="text-white text-[15px] font-extrabold text-center mt-2">
                                                Download Quotation
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col items-end justify-end gap-3 w-[100%] md:w-[50%]">
                                    <div>
                                        {" "}
                                        <button
                                            onClick={() => proceedToCheckout()}
                                            className="flex flex-row gap-3 text-xl text-primary items-center font-extrabold py-2 px-3 rounded-xl w-[210px]"
                                        >
                                            {" "}
                                            Generate Order <FaArrowRightLong />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}{" "}
                </>
            )}
        </div>
    );
};

export default QuotationOrder;
