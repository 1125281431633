import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Cookies from 'js-cookie';
import DeleteModal from "../Components/DeleteModal";
import LogoutModal from "../Components/LogoutModal";

const Topbar = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const headingMap = {
    "/": "Dashboard",
    "/dashboard": "Dashboard",
    "/importproducts": "Import Products",
    "/pricelist": "Price List",

    "/quotation": "Quotation",
    "/quotation/view": "Quotation Details",
    "/quotation/add": "Add Quotation",

    "/products": "Products",

    "/users": "Users",
    "/users/add": "Add User",
    "/users/ledger": "Customer Ledger",

    "/ordercustomer": "B2C Order Customers",
    "/order": "B2B Orders",
    "/order/view": "Order View",
    "/order/add": "Add Orders",
    "/orderDetails": "B2B Order Details",

    "/suppliers": "Suppliers",
    "/suppliers/add": "Add Supplier",
    "/suppliers/ledger": "Supplier Ledger",

    "/purchase": "Purchase Orders",
    "/purchase/add": "Add Purchase Order",
    "/purchase/view": "Purchase View",

    "/accounts": "Accounts",
    "/accounts/add": "Add Accounts",

    "/expenses": "Expenses",
    "/expenses/add": "Add Expenses",
    "/expenses/head/add": "Add Expense Head",
    "/expenses/head": "Expense Head",
    "/expenses/expenseHead": "Expense Head Details",

    "/incomes": "Incomes",
    "/incomes/add": "Add Income",
    "/transactions": "Transactions",
    "/journals": "Journal",
    "/journal/add": "Add Journal",
    "/monthly/voucher": "Monthly Closing Voucher",
    "/monthly/voucher/add": "Add Monthly Closing Voucher",

    "/carmakers": "Car Makers",
    "/news": "News"

    // Add more mappings as needed
  };

  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    setOpen(true)
  };

  const handleLogout = () => {
    setDeleting(true);
    localStorage.removeItem('user')
    Cookies.remove('XIOQUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OIMSLQ1JFVC1LRVk=')
    toast.success("Logout Successfully")
    setDeleting(false);
    navigate('/login', { replace: true });
  }

  const heading = headingMap[location.pathname] || "Page Not Found";

  return (
    <div className="flex flex-row w-full items-center justify-between px-10 py-5">
      <LogoutModal
        open={open}
        setOpen={setOpen}
        deleteFunction={handleLogout}
        deleting={deleting}
      />
      <div>
        <h1 className="text-gray-600 font-bold text-3xl ml-4 lg:ml-0">{heading}</h1>
      </div>
      <div className="flex flex-row gap-6 items-center">
        <div className=" items-center gap-1 hidden md:flex">
          <input
            type="text"
            placeholder="Search for a project"
            className="px-5 py-2 w-80 rounded-md bg-slate-100 focus:border-none focus:outline-1 outline-primary"
          />
          <button className="bg-primary px-3 py-3 font-semibold text-white rounded-lg">
            <FaSearch size={16} />
          </button>
        </div>


        <div className={` p-2 hover:cursor-pointer`}><IoLogOutOutline size={30} onClick={handleDelete} /></div>
      </div>
    </div>
  );
};

export default Topbar;