import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Loading from '../Modals/Loading';
import { useNavigate } from 'react-router-dom';
import monthlyVoucherService from '../Services/monthlyVoucher';

const AllMonthlyVouchers = () => {

    const [trigger, setTrigger] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        const getAccounts = async () => {
            try {
                setLoading(true)
                const response = await monthlyVoucherService.fetchAll()
                setData(response.monthly_vouchers)
                setLoading(false)
            }
            catch {

            }
        }
        getAccounts()
    }, [trigger])

    return (

        <div className="mx-16 my-4">
            {
                loading ?
                    <div className="">
                        <Loading />
                    </div>
                    :
                    <div>
                        <div className=" flex flex-col">
                            <div className="flex justify-end gap-4 my-2">
                                <button
                                    onClick={() => navigate('/monthly/voucher/add')}
                                    className="w-[200px] ml-3 py-2 px-4 bg-primary text-white rounded-lg hover:bg-white hover:border-primary border-2 border-white hover:text-primary duration-300"
                                >
                                    Add Monthly Voucher
                                </button>
                            </div>
                            <div className="mt-2">
                                <table className=" animate__animated animate__fadeIn">
                                    <thead className="bg-primary">
                                        <tr className=" uppercase text-sm leading-normal w-full text-white">
                                            <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                                Start Date
                                            </th>
                                            <th className=" text-center pl-5 text-[13px] w-[3%] p-[2%] ">
                                                End Date
                                            </th>
                                            <th className=" text-center pl-5 text-[13px] w-[2%] p-[2%]">
                                                Closing Income
                                            </th>
                                            <th className="pl-5 text-center text-[13px] w-[4%] p-[2%]">
                                                Closing Expense
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((monthly_voucher, index) => (
                                            <tr key={index} className="bg-gray-50">
                                                <td className="p-[2%] text-center text-[13px] w-[2%] border-b border-primary border-opacity-25">
                                                    {monthly_voucher.start_date}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-whtie  border-b border-primary border-opacity-25 ">
                                                    {monthly_voucher.end_date}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-whtie  border-b border-primary border-opacity-25 ">
                                                    {monthly_voucher.closing_income}
                                                </td>
                                                <td className="p-[2%] text-center text-[13px] w-[3%] bg-whtie  border-b border-primary border-opacity-25 ">
                                                    {monthly_voucher.closing_expense}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default AllMonthlyVouchers
