import axiosInstance from "./axiosInstance";

const userService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/users/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    login: async (payload) => {
        try {
            const response = await axiosInstance.post('/users/login', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchSingle: async (email) => {
        try {
            const response = await axiosInstance.get('/users/single/' + email);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete(`/users/delete/${id}`)
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    }

}

export default userService