import './App.css';
import { Navigate, Route, Routes, Redirect, useLocation, useNavigate } from 'react-router-dom';
import Portal from './Layout/PortalLayout';
import Dashboard from './Screen/Dashboard';
import CarMakers from './Screen/CarMakers';
import ImportProducts from './Screen/ImportProducts';
import Products from './Screen/Products';
import Users from './Screen/Users';
import OrderCustomers from './Screen/OrderCustomers';
import OrderDetail from './Screen/OrderDetail';
import Order from './Screen/Order';
import OrderView from './Screen/OrderView';
import Login from './Screen/Login';
import { useEffect, useState } from 'react';
import Suppliers from './Screen/Suppliers';
import AddSupplier from './Screen/AddSupplier';
import CustomerLedger from './Screen/CustomerLedger';
import SupplierLedger from './Screen/SupplierLedger';
import News from './Screen/News';
import AddNews from './Screen/AddNews';
import AddOrder from './Screen/AddOrder';
import PurchaseOrder from './Screen/PurchaseOrder';
import CreatePurchaseOrder from './Screen/CreatePurchaseOrder';
import PurchaseView from './Screen/PurchaseView';
import AddCustomer from './Screen/AddCustomer';
import PriceList from './Screen/PriceList';
import AddQuotation from './Screen/AddQuotation';
import Quotation from './Screen/Quotation';
import ViewQuotation from './Screen/ViewQuotation';
import QuotationOrder from "./Screen/QuotationOrder";
import Cookies from 'js-cookie';
import Account from './Screen/Account';
import AddAccount from './Screen/AddAccount';
import Expenses from './Screen/Expenses';
import AddExpensesHead from './Screen/AddExpenseHead';
import AddExpense from './Screen/AddExpense';
import AddIncome from './Screen/AddIncome';
import Income from './Screen/Income';
import AddIncomeHead from './Screen/AddIncomeHead';
import AddJournalTransaction from './Screen/AddJournalTransaction';
import ExpenseHead from './Screen/ExpenseHead';
import ExpensesByExpenseHead from './Screen/ExpensesByExpenseHead';
import JournalTransaction from './Screen/JournalTransaction';
import Transactions from './Screen/Transactions';
import AddMonthlyVoucher from './Screen/MonthlyVoucher';
import AllMonthlyVouchers from './Screen/AllMonthlyVouchers';
// import AddAccount from './Screen/AddAccount';


export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    }
  }, [isAuthenticated])

  useEffect(() => {
    setIsAuthenticated(Cookies.get("XIOQUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OIMSLQ1JFVC1LRVk="))
    // setIsAuthenticated(true)
  }, [location.pathname]);

  return (
    <Portal>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />

        <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" />} />
        <Route path="/carmakers" element={isAuthenticated ? <CarMakers /> : <Navigate to="/login" />} />

        <Route path="/news" element={isAuthenticated ? <News /> : <Navigate to="/login" />} />
        <Route path="/news/add" element={isAuthenticated ? <AddNews /> : <Navigate to="/login" />} />

        <Route path="/importproducts" element={isAuthenticated ? <ImportProducts /> : <Navigate to="/login" />} />
        <Route path="/products" element={isAuthenticated ? <Products /> : <Navigate to="/login" />} />

        <Route path="/users" element={isAuthenticated ? <Users /> : <Navigate to="/login" />} />
        <Route path="/users/add" element={isAuthenticated ? <AddCustomer /> : <Navigate to="/login" />} />
        <Route path="/users/ledger" element={isAuthenticated ? <CustomerLedger /> : <Navigate to="/login" />} />

        <Route path="/suppliers" element={isAuthenticated ? <Suppliers /> : <Navigate to="/login" />} />
        <Route path="/suppliers/ledger" element={isAuthenticated ? <SupplierLedger /> : <Navigate to="/login" />} />
        <Route path="/ordercustomer" element={isAuthenticated ? <OrderCustomers /> : <Navigate to="/login" />} />

        <Route path="/suppliers/add" element={isAuthenticated ? <AddSupplier /> : <Navigate to="/login" />} />

        <Route path="/order" element={isAuthenticated ? <Order /> : <Navigate to="/login" />} />
        <Route path="/order/add" element={isAuthenticated ? <AddOrder /> : <Navigate to="/login" />} />
        <Route path="/order/view" element={isAuthenticated ? <OrderView /> : <Navigate to="/login" />} />

        <Route path="/orderDetails" element={isAuthenticated ? <OrderDetail /> : <Navigate to="/login" />} />

        <Route path="/purchase" element={isAuthenticated ? <PurchaseOrder /> : <Navigate to="/login" />} />
        <Route path="/purchase/view" element={isAuthenticated ? <PurchaseView /> : <Navigate to="/login" />} />
        <Route path="/purchase/add" element={isAuthenticated ? <CreatePurchaseOrder /> : <Navigate to="/login" />} />

        <Route path="/pricelist" element={isAuthenticated ? <PriceList /> : <Navigate to="/login" />} />

        <Route path="/quotation" element={isAuthenticated ? <Quotation /> : <Navigate to="/login" />} />
        <Route path="/quotation/add" element={isAuthenticated ? <AddQuotation /> : <Navigate to="/login" />} />
        <Route path="/quotation/view" element={isAuthenticated ? <ViewQuotation /> : <Navigate to="/login" />} />
        <Route path="/quotation/get" element={isAuthenticated ? <QuotationOrder /> : <Navigate to="/login" />} />

        <Route path="/accounts" element={isAuthenticated ? <Account /> : <Navigate to="/login" />} />
        <Route path="/accounts/add" element={isAuthenticated ? <AddAccount /> : <Navigate to="/login" />} />

        <Route path="/expenses" element={isAuthenticated ? <Expenses /> : <Navigate to="/login" />} />
        <Route path="/expenses/add" element={isAuthenticated ? <AddExpense /> : <Navigate to="/login" />} />
        <Route path="/expenses/head" element={isAuthenticated ? <ExpenseHead /> : <Navigate to="/login" />} />
        <Route path="/expenses/expenseHead" element={isAuthenticated ? <ExpensesByExpenseHead /> : <Navigate to="/login" />} />
        <Route path="/expenses/head/add" element={isAuthenticated ? <AddExpensesHead /> : <Navigate to="/login" />} />

        <Route path="/incomes" element={isAuthenticated ? <Income /> : <Navigate to="/login" />} />
        <Route path="/incomes/add" element={isAuthenticated ? <AddIncome /> : <Navigate to="/login" />} />

        <Route path="/journals" element={isAuthenticated ? <JournalTransaction /> : <Navigate to="/login" />} />
        <Route path="/journal/add" element={isAuthenticated ? <AddJournalTransaction /> : <Navigate to="/login" />} />

        <Route path="/transactions" element={isAuthenticated ? <Transactions /> : <Navigate to="/login" />} />

        <Route path="/monthly/voucher" element={isAuthenticated ? <AllMonthlyVouchers /> : <Navigate to="/login" />} />
        <Route path="/monthly/voucher/add" element={isAuthenticated ? <AddMonthlyVoucher /> : <Navigate to="/login" />} />

      </Routes>
    </Portal>
  );
}
